import { Component, OnInit, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { Company } from '../../shared/models/Company';
import { CompanyService } from '../../shared/services/company.service';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'companyForm',
    templateUrl: './companyForm.component.html',
    host: { 'class': 'container' }
})
export class CompanyFormComponent implements OnInit {

    constructor(private companyService: CompanyService, private activatedRoute: ActivatedRoute, private _location: Location) { }
        
    model: Company = undefined; 
    originalCompany: Company;
    success = false;
    loading = false;
    errorMessage: string = '';

    onSubmit() {
        this.loading = true;

        if (this.model.id == 0) {

            this.companyService.addCompany(this.model).subscribe(result => {
                this.loading = false;
                this.model = {
                    id: 0,
                    name: "",
                    lat: 0,
                    lng: 0,
                    enableNegativeJourneys: false
                };
                this.success = true;
            }, error => this.errorMessage = <any>error);
        }
        else {
            this.companyService.updateCompany(this.originalCompany, this.model).subscribe(result => {
                this.loading = false;                
                this.success = true;
            }, error => this.errorMessage = <any>error);
        }
    }

    ngOnInit() { 
        this.model = {
            id: 0,
            name: "",
            lat: 0,
            lng: 0,
            enableNegativeJourneys: false
        };     
        var companyId = this.activatedRoute.snapshot.params['id'];        
        if (companyId != undefined && companyId != 'new') {
            this.companyService.getCompany(companyId).subscribe(result => {
                if (result != undefined) {
                    this.model = Object.assign({}, result);
                    this.originalCompany = Object.assign({}, result);
                    this.success = false;
                }
            });
        }
    }

    goback() {
        this._location.back();
    }
    

}
