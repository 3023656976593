
import {throwError as observableThrowError,  Observable, Subscription, BehaviorSubject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, Inject, Injector, OnInit } from '@angular/core';
import { ResponseContentType } from '@angular/http';
import { AuthHttpService } from './auth-http.service';
import { ORIGIN_URL } from '../constants/baseurl.constants';
import { VehicleLocation } from '../../shared/models/VehicleLocation';
import { Vehicle } from '../../shared/models/Vehicle';
import { Journey } from '../../shared/models/Journey';
import { JourneyReport } from '../../shared/models/JourneyReport';
import { IdleReport } from '../../shared/models/IdleReport';
import { IgnitionReport } from '../../shared/models/IgnitionReport';
import { ReportParams } from '../../shared/models/ReportParams';
import { HttpResponse, HttpParams } from '@angular/common/http';
import * as moment from 'moment';





@Injectable()
export class JourneyService {


    constructor(private http: AuthHttpService, @Inject(ORIGIN_URL) private baseUrl: string) {
    }

    public selectedJourney = new BehaviorSubject(null);


    getJourneysForVehicle(vehicle: Vehicle, reportParams: ReportParams): Observable<JourneyReport> {


        var start = new Date(reportParams.from.year, reportParams.from.month - 1, reportParams.from.day, reportParams.fromTime.hour, reportParams.fromTime.minute, reportParams.fromTime.second);
        var end = new Date(reportParams.to.year, reportParams.to.month - 1, reportParams.to.day, reportParams.toTime.hour, reportParams.toTime.minute, reportParams.toTime.second);

        let params: HttpParams = new HttpParams();
        params = params.append('vehicleId', vehicle.id.toString());
        params = params.append('start', start.toUTCString());
        params = params.append('end', end.toUTCString());
        params = params.append('hoursMode', reportParams.hoursMode);
        params = params.append('weekdayEveningsMode', reportParams.weekdayEveningsMode);        
        params = params.append('bankHolidaysMode', reportParams.bankHolidays);     
        params = params.append('weekendMode', reportParams.weekendMode);
        params = params.append('onlyOverspeed', reportParams.onlyOverspeed != undefined ? reportParams.onlyOverspeed.toString() : "false");
        params = params.append('overrideSpeed', reportParams.overrideSpeed != undefined ? reportParams.overrideSpeed.toString() : "0");
        // Go get the journeys     

      return this.http.get(`/api/journeys/`, params).pipe(map((res: JourneyReport) => res),catchError(this.handleError),);
    }

    exportJourneysForVehicle(vehicles: Vehicle[], reportParams: ReportParams): Observable<Blob> {

        var start = new Date(reportParams.from.year, reportParams.from.month - 1, reportParams.from.day, reportParams.fromTime.hour, reportParams.fromTime.minute, reportParams.fromTime.second);
        var end = new Date(reportParams.to.year, reportParams.to.month - 1, reportParams.to.day, reportParams.toTime.hour, reportParams.toTime.minute, reportParams.toTime.second);

      let params: HttpParams = new HttpParams();
        params = params.append('vehicleIds', vehicles.map(vehicle => vehicle.id).join(',').toString());
        params = params.append('start', start.toUTCString());
        params = params.append('end', end.toUTCString());
        params = params.append('hoursMode', reportParams.hoursMode);
        params = params.append('weekdayEveningsMode', reportParams.weekdayEveningsMode);
        params = params.append('bankHolidaysMode', reportParams.bankHolidays);
        params = params.append('weekendMode', reportParams.weekendMode);
        params = params.append('onlyOverspeed', reportParams.onlyOverspeed != undefined ? reportParams.onlyOverspeed.toString() : "false");
        params = params.append('overrideSpeed', reportParams.overrideSpeed != undefined ? reportParams.overrideSpeed.toString() : "0");
        // Go get the journeys        
      return this.http.get(`/api/journeys/export`, params,  ResponseContentType.Blob).pipe(catchError(this.handleError));
    }

    getIdlesForVehicle(vehicle: Vehicle, reportParams: ReportParams) {
        var start = new Date(reportParams.from.year, reportParams.from.month - 1, reportParams.from.day, reportParams.fromTime.hour, reportParams.fromTime.minute, reportParams.fromTime.second);
        var end = new Date(reportParams.to.year, reportParams.to.month - 1, reportParams.to.day, reportParams.toTime.hour, reportParams.toTime.minute, reportParams.toTime.second);

      let params: HttpParams = new HttpParams();
        params = params.append('vehicleId', vehicle.id.toString());
        params = params.append('start', start.toUTCString());
        params = params.append('end', end.toUTCString());
        params = params.append('hoursMode', reportParams.hoursMode);
        params = params.append('weekdayEveningsMode', reportParams.weekdayEveningsMode);
        params = params.append('bankHolidaysMode', reportParams.bankHolidays);     
        params = params.append('weekendMode', reportParams.weekendMode);
        params = params.append('minIdle', reportParams.minIdle.toString());        
        // Go get the journeys        
      return this.http.get(`/api/journeys/idle`, params).pipe(map(response => {
        return (<IdleReport[]>response);            
        }),catchError(this.handleError),);
    }

  getIgnitionsForVehicle(vehicle: Vehicle, reportParams: ReportParams) {
    var start = new Date(reportParams.from.year, reportParams.from.month - 1, reportParams.from.day, reportParams.fromTime.hour, reportParams.fromTime.minute, reportParams.fromTime.second);
    var end = new Date(reportParams.to.year, reportParams.to.month - 1, reportParams.to.day, reportParams.toTime.hour, reportParams.toTime.minute, reportParams.toTime.second);

    let params: HttpParams = new HttpParams();
    params = params.append('vehicleId', vehicle.id.toString());
    params = params.append('start', start.toUTCString());
    params = params.append('end', end.toUTCString());
    params = params.append('hoursMode', reportParams.hoursMode);
    params = params.append('weekdayEveningsMode', reportParams.weekdayEveningsMode);
    params = params.append('bankHolidaysMode', reportParams.bankHolidays);
    params = params.append('weekendMode', reportParams.weekendMode);
    params = params.append('minIgnition', reportParams.minIgnition.toString());
    // Go get the journeys        
    return this.http.get(`/api/journeys/ignition`, params).pipe(map(response => {
      return (<IgnitionReport[]>response);
    }), catchError(this.handleError));
  }


    getPointsOnJourney(journey : Journey) {
        let params: HttpParams = new HttpParams();
        params = params.append('device', journey.deviceId.toString());
        params = params.append('start', moment(journey.startTime).toISOString());
        params = params.append('end', moment(journey.endTime).toISOString());

        // Go get the journeys        
        return this.http.get(`/api/journeys/points/`, params).pipe(map(response => {
            return (<VehicleLocation[]>response);
        }),catchError(this.handleError),);

    }

    reviveDateTime(key: any, value: any): any {
        if (typeof value === 'string') {
            if (moment(value).isValid()) {
                return moment(value);
            };            
        }
        return value;
    }

    selectJourney(journey: Journey) {
        this.selectedJourney.next(journey);
    }
  
    private handleError(error: HttpResponse<any> | any) {
        console.log("error:", error);
        // In a real world app, you might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            const body = error || '';
            const err = body['error'] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }
}
