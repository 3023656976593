import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from '../../shared/services/auth.service';

interface Credentials {
    username: string,
    password: string,
    remember: boolean
}

@Component({   
    templateUrl: './login.component.html',
    host: { 'class': 'container' }
})
export class LoginComponent implements OnInit {        

    credentials: Credentials = { username: '', password: '', remember: false};
    loading = false;
    submitted = false;
    returnUrl: string;    
    errorMessage: string = '';
    @ViewChild('loginForm') form;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private auth: AuthService) { }

    ngOnInit() {
        // reset login status
        this.auth.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    onLogin() {      
        
        if (!this.form.valid) {
            this.submitted = true;
            return;
        }        
        this.loading = true;
        this.auth.login(this.credentials)
            .subscribe(
              data => {
                this.router.navigate([this.returnUrl]);
            },
              error => {
                this.errorMessage = error;
                this.loading = false;
            });
    }
}
