﻿import { Component } from '@angular/core';


@Component({   
    templateUrl: './download.component.html',
    host: { 'class': 'container' }
})
export class DownloadComponent {        


}
