
import {forkJoin as observableForkJoin,  Observable, Subscription } from 'rxjs';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { Vehicle } from '../../shared/models/Vehicle';
import { VehicleService } from '../../shared/services/vehicle.service';
import { EventService } from '../../shared/services/event.service';
import { VehicleEvent } from '../../shared/models/VehicleEvent';
import { ReportParams } from '../../shared/models/ReportParams'

@Component({
    selector: 'eventReport',
    templateUrl: './eventReport.component.html',   
    styleUrls: ['./eventReport.component.scss']
})
export class EventReportComponent implements OnInit {

    @Input('report') report: string = '';
    @Input('reportTitle') reportTitle: string = '';

    selectedVehicles: Vehicle[] = [];
    resultVehicles: Vehicle[] = [];

    gotEvents: boolean = false;
    gotResults: boolean = false;
    noResults: boolean = false;

    errorMessage: string = '';
    model: ReportParams = {
        reportTitle: '',
        from: undefined,
        fromTime: {
            "hour": 0,
            "minute": 0,
            "second": 0
        },
        to: undefined,
        toTime: {
            "hour": 23,
            "minute": 59,
            "second": 59
        },
        hoursMode: 'includeAll',
        weekdayEveningsMode: 'include',
        weekendMode: 'include',
        bankHolidays: 'include',        
    }; 

    loading = false;

    constructor(
        private vehicleService: VehicleService,
        private eventService: EventService
    ) { }

    ngOnInit() {

        this.vehicleService.selectedVehicles.subscribe(data => {
            this.selectedVehicles = data;
        });

    }

    getTotal(items, prop) {
        return items.reduce(function (a, b) {
            return a + b[prop];
        }, 0);
    };

    getTimeString(ms) {
        ms = ms / 1000;
        ms = ms / 60;
        if (ms < 60) {
            return ms.toFixed(0) + ' mins';
        }
        var hrs = ms / 60;
        var mins = ms % 60;
        if (mins < 10) {
            return hrs.toFixed(0) + ':0' + mins.toFixed(0);
        }
        else {
            return hrs.toFixed(0) + ':' + mins.toFixed(0);
        }
    }

    generateReport() {

        this.errorMessage = '';

        if (this.selectedVehicles.length < 1) {
            this.errorMessage = "Please select at least one vehicle";
            return;
        }

        this.loading = true;

        var start = new Date(this.model.from.year, this.model.from.month - 1, this.model.from.day, this.model.fromTime.hour, this.model.fromTime.minute, this.model.fromTime.second);
        var end = new Date(this.model.to.year, this.model.to.month - 1, this.model.to.day, this.model.toTime.hour, this.model.toTime.minute, this.model.toTime.second);        
        
        switch (this.report) {
            case 'behaviour':
                this.generateBehaviourReport(start, end, this.model.hoursMode, this.model.weekdayEveningsMode);
                break;                        
        }
    }

    backToForm() {
        this.gotResults = false;
        this.gotEvents = false;
    }

    generateBehaviourReport(start, end, hoursMode, weekdayEveningsMode) {


        var subscriptions: Observable<VehicleEvent[]>[] = [];

        this.selectedVehicles.forEach(vehicle => {
            subscriptions.push(this.eventService.getEventsForVehicle(vehicle, start, end));
        });

        observableForkJoin(subscriptions).subscribe(data => {
            this.noResults = true;
            this.resultVehicles = [];

            data.forEach(eventCollection => {
                if (eventCollection.length > 0) {
                    var vehicle = this.selectedVehicles.find(x => x.deviceId == eventCollection[0].deviceId);
                    vehicle.events = eventCollection;
                    this.resultVehicles.push(vehicle);
                    this.noResults = false;
                }
            });
            this.gotEvents = true;
            this.gotResults = true;
            this.loading = false;
        });
    }

    getRowClass(row) {
        switch (row.name) {
            case "Harsh Braking":
                return "braking";                
            case "Harsh Acceleration":
                return "acceleration";                
            case "Harsh Cornering":
                return "cornering";                
        }
        return "";
    }


}
