
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { AuthHttpService } from './auth-http.service';
import { ORIGIN_URL } from '../constants/baseurl.constants';
import { Company } from '../../shared/models/Company';


import * as jsonpatch from 'fast-json-patch';

@Injectable()
export class CompanyService {

  
    constructor(private http: AuthHttpService, @Inject(ORIGIN_URL) private baseUrl: string) {              
    }

    get companies() {
        return this.http.get(`/api/companies/`).pipe(map((res: Company[]) => res),catchError(this.handleError),);
    }

    getCompany(id: number): Observable<Company> {        
        return this.http.get(`/api/companies/` + id).pipe(map((response: Company) => response));
    }

    deleteCompany(user: Company): Observable<any> {
        return this.http.delete(`/api/companies/` + user.id);
    }

  
    addCompany(user: Company) {
        // Update icon
        return this.http.post(`/api/companies`, user).pipe(map((response: Company) => response),catchError(this.handleError),);                   
    }

    updateCompany(user: Company, newCompany: Company) {

        var patchDocument = jsonpatch.default.compare(user, newCompany)
        return this.http.patch(`/api/companies/` + newCompany.id, patchDocument).pipe(map((response : HttpResponse<any>) => response),catchError(this.handleError),);       

    }
    private handleError(error: HttpResponse<any> | any) {
        // In a real world app, you might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            const body = error || '';
            const err = body['error'] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }

}
