
import {throwError as observableThrowError, of as observableOf,  Observable ,  BehaviorSubject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { AuthHttpService } from './auth-http.service';
import { ORIGIN_URL } from '../constants/baseurl.constants';
import { User } from '../../shared/models/User';



import * as jsonpatch from 'fast-json-patch';

@Injectable()
export class UserService {

  
    constructor(private http: AuthHttpService, @Inject(ORIGIN_URL) private baseUrl: string) {              
    }

    get users() {
      return this.http.get(`/api/users/`)
        .pipe(map((res: User[]) => res), catchError(this.handleError));
    }

    getUser(id: string): Observable<User> {
      if (id == 'new') {
        return observableOf({
          firstName: "",
          lastName: "",
          userName: "",
          id: "new",
          company: undefined,
          companyId: 0,
          accessLevel: 3,
          passwordUpdate: "",
          email: ""
        });
      }
      return this.http.get(`/api/users/` + id).pipe(map((response: User) => response));
    }

    deleteUser(user: User) {
      return this.http.delete(`/api/users/` + user.id)
        .pipe(
          catchError(this.handleError));
    }

  
    addUser(user: User) {
        // Update icon
        return this.http.post(`/api/users`, user).pipe(map((response: User) => response),catchError(this.handleError),);                   
    }

    updateUser(user: User, newUser: User) {

        var patchDocument = jsonpatch.default.compare(user, newUser)
        return this.http.patch(`/api/users/` + newUser.id, patchDocument).pipe(map(response => response),catchError(this.handleError),);       

    }
    private handleError(error: HttpResponse<any> | any) {
        // In a real world app, you might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            const body = error || '';
            const err = body['error'] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }

}
