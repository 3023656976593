
import {first} from 'rxjs/operators';
import { Component, OnInit, Inject, Input, OnDestroy } from '@angular/core';
import { ScheduledReport } from '../../shared/models/ScheduledReport';
import { ScheduledReportsService } from '../../shared/services/scheduledReports.service';
import { Observable, Subscription } from 'rxjs';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AuthService } from '../../shared/services/auth.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'scheduledReportList',
  templateUrl: './scheduledReportList.component.html',
  styleUrls: ['./scheduledReportList.component.scss'],
    host: { 'class': 'container' }

})
export class ScheduledReportListComponent implements OnInit {
    reports: ScheduledReport[];
    selectedReport: ScheduledReport;    
    loading: boolean = true;   
    
       
    public config: PaginationInstance;
    
    constructor( @Inject(PLATFORM_ID) private platformId: Object, private auth: AuthService, private scheduledReportService: ScheduledReportsService) { }

    ngOnInit() {       

        this.loading = true;
        //this.config = {
        //    id: 'custom',
        //    itemsPerPage: 10,
        //    currentPage: 1
        //};

        //var showInListOption = localStorage.getItem('showInList');
        //if (showInListOption != undefined) {
        //    this.config.itemsPerPage = parseInt(showInListOption);
        //}
            
        this.auth.currentUser.pipe(first()).subscribe(data => {
            // When we have a user, get their scheduled reports
            this.scheduledReportService.scheduledReports.subscribe(data => {                    
              this.reports = data;                                  
              this.loading = false;
            });
            this.scheduledReportService.loadAll();
        });

    }


    //changeShowAmount() {        
    //    localStorage.setItem('showInReportList', this.config.itemsPerPage.toString());        
    //}

    onSelect(report: ScheduledReport): void {
        if (report == this.selectedReport) {
            this.selectedReport = undefined;
                this.scheduledReportService.selectReport(undefined);
            }
            else {
            this.selectedReport = report;
            this.scheduledReportService.selectReport(report);
            }
       
    }

    
}

