import { GoogleMapsAPIWrapper } from '@agm/core';
import { Directive, Input, Output } from '@angular/core';

declare var google: any;

@Directive({
    selector: 'agm-map-directions'
})
export class DirectionsMapDirective {
    @Input() origin: any;
    @Input() destination: any;
    @Input() waypoints: any;
    @Input() directionsDisplay: any;
    @Input() estimatedTime: any;
    @Input() estimatedDistance: any;

    constructor(private gmapsApi: GoogleMapsAPIWrapper) { }
    updateDirections() {
        this.gmapsApi.getNativeMap().then(map => {            
            var directionsService = new google.maps.DirectionsService;
            var me = this;
            var latLngA = new google.maps.LatLng({ lat: this.origin.latitude, lng: this.origin.longitude });
            var latLngB = new google.maps.LatLng({ lat: this.destination.latitude, lng: this.destination.longitude });
            this.directionsDisplay.setMap(map);
            this.directionsDisplay.setOptions({
                polylineOptions: {
                    strokeWeight: 8,
                    strokeOpacity: 0.7,
                    strokeColor: '#129acc'
                }
            });
            this.directionsDisplay.setDirections({ routes: [] });            
            directionsService.route({
                origin: latLngA,
                destination: latLngB,
                avoidHighways: true,
                waypoints: this.waypoints,
                travelMode: google.maps.DirectionsTravelMode.DRIVING
                //travelMode: 'DRIVING'
            }, function (response: any, status: any) {
                if (status === 'OK') {
                    me.directionsDisplay.setDirections(response);
                    map.setZoom(30);
                    //console.log(me.getcomputeDistance (latLngA, latLngB));
                    var point = response.routes[0].legs[0];
                    me.estimatedTime = point.duration.text;
                    me.estimatedDistance = point.distance.text;
                    console.log(me.estimatedTime);
                } 
            });
        });

    }

    private getcomputeDistance(latLngA: any, latLngB: any) {
        return (google.maps.geometry.spherical.computeDistanceBetween(latLngA, latLngB) / 1000).toFixed(2);
    }
}
