import { Injectable, Inject } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { Journey } from '../../shared/models/Journey';
import { Vehicle } from '../../shared/models/Vehicle';
import { VehicleLocation } from '../../shared/models/VehicleLocation';
import { MapPosition } from '../../shared/models/MapPosition';
import { MapMarker } from '../models/MapMarker';

@Injectable()
export class MapService {

    public mode: BehaviorSubject<string> = new BehaviorSubject('');
    public journey: BehaviorSubject<Journey> = new BehaviorSubject(undefined);
    public selectedVehicles: BehaviorSubject<Vehicle[]> = new BehaviorSubject([]);
    public requestedPos: BehaviorSubject<MapPosition> = new BehaviorSubject(undefined);
    public mapInit: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public mapFullscreen: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public mapMovedByUser: BehaviorSubject<number> = new BehaviorSubject(1);

    public map: any;

    constructor() {
        this.mode.next('');
        this.journey.next(undefined);
        this.selectedVehicles.next([]);
        this.requestedPos.next({
            lat: 51.820413,
            lng: -0.839938,
            zoom: 18
        });
    }

    getIcon(icon: string, color: string, identifier: string, location: VehicleLocation, markerMode: string, callback) {

        var backgroundType: string = "Bubble";
        if (markerMode.indexOf("direction") >= 0) {
          backgroundType = "Pill";
        }

        var canvas, ctx;
        canvas = document.createElement('canvas');
        ctx = canvas.getContext('2d');
        var background = new Image();
        canvas.height = 93;
        canvas.width = 80;

        // Draw the icon or directional arrow
        if (markerMode.indexOf("icon") >= 0) {
            ctx.fillStyle = color;
            ctx.font = '18px icomoon';
            if (icon.indexOf('fa') > -1) {
                ctx.font = '18px icomoon';
            }
          ctx.textAlign = "center";
            var glyph = this.getIconString(icon);
          ctx.fillText(String.fromCharCode(parseInt(glyph)), 40, 22);
        }
        else if (markerMode.indexOf("direction") >= 0) {
            var expiredBefore = new Date();
            expiredBefore.setHours(new Date().getHours() - 2);
            ctx.fillStyle = this.getStatusColor(location);
            ctx.textAlign = "center";
            ctx.textBaseline = "top";
            var fontSize = 14;
            if (location != undefined && location.speed > 0 && location.attributes.ignition && new Date(location.deviceTime) > expiredBefore) {
              // Draw arrow as location is current and moving
                ctx.font = '25px icomoon';
                ctx.translate(40, 15);
                ctx.rotate(location.course * Math.PI / 180);
                ctx.fillText(String.fromCharCode(0xe919), -8, -10);
                // Rotate the canvas back!
                ctx.rotate(-location.course * Math.PI / 180);                
            } else if (location != undefined && new Date(location.deviceTime) < expiredBefore) {
                // Draw question mark as location is old                
                ctx.font = '20px icomoon';
                ctx.translate(40, 10);
                ctx.fillText(String.fromCharCode(0xe905), 0, -4);
            } else {
                // Vehicle is stopped
                ctx.font = '20px icomoon';
                ctx.translate(40, 0);
                ctx.fillText(String.fromCharCode(0xe917), 0, -2);
            }
            ctx.translate(-40, 0);
        }

        // If we are in direction or icon mode we can end here since there is no text to render, just need to pop the background on if it's icon mode
        if (markerMode.toLowerCase().indexOf("name") < 1) {
            var yAnchor = 10;
            if (markerMode.toLowerCase() == 'icon') {
              background.src = "/images/map-marker-background.png";
              ctx.drawImage(background, 15, 0);
                yAnchor = 50;
            }
            var marker: MapMarker = {
                url: canvas.toDataURL(),
                anchor: { x: 40, y: yAnchor },
                size: {
                    height: 80,
                    width: 80
                }
            }
            callback(marker);
            return;
        }

        // Add the postcode on (if it's available and requested)
        if (markerMode.toLowerCase().indexOf('postcode') > 0 && location != undefined) {
            var postcodeRegex = / ([A-Z]{1,2}[0-9]{1,2}( [1-9]{1,2}[A-Z]{1,2})?)/g;
                                   
              let match = postcodeRegex.exec(location.address);
              if (match != null) {
                  identifier = identifier += ' - ' + match[1];
              }
           
        }

        // Render the text 
        var identifierParts = identifier.split(' - ');
        var lineHeight = 13;
        var line = 0;       
        var offset = 36;
        if (markerMode.indexOf("icon") < 0) {
            // If it's a direction indicator, the background needs to start lower down
            offset = 28;
        }
        
        ctx.fillStyle = '#787878';
        identifierParts.forEach(identifierPart => {
            ctx.font = 'bold 11px nunito';
            var fontSize = 11;
            while (ctx.measureText(identifierPart).width > 70 && fontSize > 5) {
                fontSize--;
                ctx.font = 'bold' + fontSize + 'px nunito';
            }
            ctx.fillText(identifierPart, 40, line * lineHeight + offset);
            line++;
        });

      var yAnchor = 10;
        if (markerMode.indexOf("icon") >= 0) {
            yAnchor = 54 + (identifierParts.length * lineHeight);
        }

        // Render the correct background
        switch (identifierParts.length) {
            case 1:
                if (markerMode.indexOf("icon") >= 0) {
                  background.src = "/images/map-marker-background-large-1line.png";
                }
                else {
                  background.src = "/images/map-marker-background-pill-1line.png";
                }
                break;
            case 2:
                if (markerMode.indexOf("icon") >= 0) {
                  background.src = "/images/map-marker-background-large-2line.png";
                }
                else {
                  background.src = "/images/map-marker-background-pill-2line.png";
                }
                break;
            case 3:
                if (markerMode.indexOf("icon") >= 0) {
                  background.src = "/images/map-marker-background-large-3line.png";
                }
                else {
                  background.src = "/images/map-marker-background-pill-3line.png";
                }
                break;
        }

        if (markerMode.indexOf("icon") < 0) {
            // If it's a direction indicator, the background needs to start lower down
            ctx.translate(0, 15);
        }
        ctx.globalCompositeOperation = "destination-over";
        ctx.drawImage(background, 0, 0);

        var marker: MapMarker = {
            url: canvas.toDataURL(),
            anchor: { x: 40, y: yAnchor },
            size: {
                height: 93,
                width: 80
            }
        }
        callback(marker);
        return
    }

    getStatusColor(location: VehicleLocation) {

        if (location == undefined) {
            return '#0BA025';
        }

        if (!location.attributes.ignition) {
            return '#FF5733';
        }
        else if (location.speed == 0) {
            return '#FFBD33';
        }
        return '#0BA025';
    }

    getIconString(iconClass) {
        switch (iconClass) {

            case "bicycle":
                return "0xe913";
            case "car":
                return "0xe912";
            case "car-1":
                return "0xe911";
            case "car-2":
                return "0xe910";
            case "pickup-truck":
                return "0xe906";
            case "van":
                return "0xe901";
            case "van-1":
                return "0xe900";
            case "mobile":
              return "0xe909";
            case "question":
              return "0xe905";
            case "stop":
            return "0xe917";
          //icomoon arrow-up is used as a base to rotate and show direction of the vehicle
            case "arrow":
              return "0xe919";
        }
    }
}
