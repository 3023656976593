
import {of as observableOf, throwError as observableThrowError,  BehaviorSubject ,  Observable } from 'rxjs';

import {catchError, take, map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt'
import { User } from '../models/User';
import { Company } from '../models/Company';
import { ORIGIN_URL } from '../constants/baseurl.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';


@Injectable()
export class AuthService {

  public currentUser = new BehaviorSubject(null)
  jwtHelper: JwtHelperService = new JwtHelperService();
  refreshSubscription: any;

  constructor(private http: HttpClient, @Inject(ORIGIN_URL) private baseUrl: string, private router: Router, private activatedRoute: ActivatedRoute) { }

  login(credentials) {
    return this.http.post('/api/login', credentials).pipe(
      map((data: HttpResponse<any>) => {        
        localStorage.setItem('token', data['access_token']);
        localStorage.setItem('refresh', data['refresh_token']);
        
        var token = this.jwtHelper.decodeToken(data['access_token']);
        var user: User = <User>{
          firstName: token.firstName,
          lastName: token.lastName,
          accessLevel: token.level,
          userName: token.userName,
          id: token.sub,
          company: <Company>JSON.parse(token.company),
          companyId: token.companyId
        };        
        this.currentUser.next(user);
      }),catchError(this.handleError),);
  }

  loggedIn() {
    return new Promise((resolve, reject) => {
      if (!this.jwtHelper.isTokenExpired(this.getToken('token'))) {
        resolve();
      }
      else if (!this.jwtHelper.isTokenExpired(this.getToken('refresh'))) {
        return this.getNewJwt().pipe(take(1)).subscribe(data => {
          if (data) {
            resolve();
          }
          else {
            reject();
          }
        });
      }
      else {
        reject();
      }
    });
  };

  logout() {
    localStorage.removeItem('token');
    this.currentUser.next(undefined);
  }

  forgotten(username) {
    return this.http.post('/api/users/forgottenpassword', { value: username }).pipe(catchError(this.handleError));
  }

  checkResetToken(token, userid): Observable<any>  {
    return this.http.post('/api/users/checkresettoken', { token: token, userid: userid }).pipe(catchError(this.handleError));
  }

  resetPassword(token, userid, password, confirmPassword) {
    return this.http.post('/api/users/resetPassword', { token: token, userid: userid, newPassword: password, confirmPassword: confirmPassword }).pipe(catchError(this.handleError));
  }


  private handleError(response: HttpResponse<any>) {    
    let errMsg: string;
    console.log("Handle Error");
    if (response['error'] === null) {
      errMsg = "Please check your username and try again";
    }
    else {
      errMsg = response['error'].error;
    }
    return observableThrowError(errMsg);
  }

  public getToken(tokenName: string) {
    return localStorage.getItem(tokenName);
  }

  public getNewJwt(): Observable<boolean> {

    var refreshToken = localStorage.getItem('refresh');
    let jwtExp = this.jwtHelper.decodeToken(refreshToken).exp;
    var exp = new Date(0);
    exp.setUTCSeconds(jwtExp)
    if (exp < new Date()) {
      // Refresh token is expired, logout
      this.currentUser.next(undefined);
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.activatedRoute.snapshot.url } });
      return observableOf(false);
    }

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    // Go get the new token            
    return this.http.post(`/api/login/refresh?`, { value: refreshToken }, { headers: headers }).pipe(map((res: HttpResponse<any>) => {
      var data = res;
      localStorage.setItem('token', data['access_token']);
      var token = this.jwtHelper.decodeToken(data['access_token']);
      var user: User = <User>{
        firstName: token.firstName,
        lastName: token.lastName,
        accessLevel: token.level,
        userName: token.userName,
        id: token.sub,
        company: <Company>JSON.parse(token.company),
        companyId: token.companyId
      };
      this.currentUser.next(user);
      return true;
    }),catchError((err: any) => {
      return observableOf(false);
    }),);
  }

}
