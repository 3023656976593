
import {mergeMap, map, filter} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject, ViewEncapsulation, RendererFactory2, PLATFORM_ID, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, PRIMARY_OUTLET, CanActivate } from '@angular/router';
import { Meta, Title, DOCUMENT, MetaDefinition } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { LinkService } from './shared/services/link.service';
import { MapService } from './shared/services/map.service';
import { VehicleService } from './shared/services/vehicle.service';
import { Vehicle } from './shared/models/Vehicle';
import { Journey } from './shared/models/Journey';
import { MapPosition } from './shared/models/MapPosition';
import { MapsAPILoader, GoogleMapsAPIWrapper } from '@agm/core';
import { AuthService } from './shared/services/auth.service';
import { Company } from './shared/models/Company';
import { isEqual } from 'lodash';
import { User } from './shared/models/User';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Zones } from './shared/models/Zones';


// i18n support
import { TranslateService } from '@ngx-translate/core';
import { REQUEST } from './shared/constants/request';




@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class AppComponent implements OnInit, OnDestroy {

    // This will go at the END of your title for example "Home - Angular Universal..." <-- after the dash (-)
    private endPageTitle: string = '';
    // If no Title is provided, we'll use a default one before the dash(-)
  private defaultPageTitle: string = 'Citytalk Tracking';
  private closeResult: string;
  private routerSub$: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private linkService: LinkService,
    public translate: TranslateService,
    @Inject(REQUEST) private request,
    private mapService: MapService,
    private vehicleService: VehicleService,
    private mapsAPILoader: MapsAPILoader,
    private auth: AuthService,
    private ref: ChangeDetectorRef,
    public mapApiWrapper: GoogleMapsAPIWrapper,
    private modalService: NgbModal,
  ) {
    translate.setDefaultLang('en');
    translate.use('en');

    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        mapService.mode.next('');
        mapService.journey.next(undefined);
        mapService.selectedVehicles.next([]);
        vehicleService.selectVehicle(undefined);
        vehicleService.selectVehicles([]);
      }
    });
  }

  @ViewChild('vehicleMap') vehicleMap;
  // gets the map DOM id
  @ViewChild('googleMap') vehicleMapContainer: ElementRef;

  vehicles: Vehicle[] = [];
  congestionZones: Zones = <Zones>{};
  submitted = false;
  mapMode: string;
  mapClass: string;
  mapInit: boolean = false;
  mapFullscreen: boolean = false;
  showIdentifiers: boolean = false;
  markerMode: string = 'iconName';

    journey: Journey = undefined;
    position: MapPosition = {
        lat: 51.820413,
        lng: -0.839938,
        zoom: 18
    };
    company: Company;
    currentUser: User;
    hideControlsTimeout: any;
    controlsHidden: boolean = false;

  ngOnInit() {
        // Change "Title" on every navigationEnd event
        // Titles come from the data.title property on all Routes (see app.routes.ts)
        this._changeTitleOnNavigation();       


        var showIdentifiersSetting = localStorage.getItem('showIdentifiers');
        var markerModeSetting = localStorage.getItem('markerMode');

        this.showIdentifiers = showIdentifiersSetting == "true";
        if (markerModeSetting != undefined) {
            this.markerMode = markerModeSetting;
        }

        this.auth.currentUser.subscribe(data => {
            if (data != null) {
                this.currentUser = data;
                this.company = data.company;
            }
        });    

        this.mapsAPILoader.load().then(() => {
            if (this.company != undefined) {
                this.position = {
                    lat: this.company.lat,
                    lng: this.company.lng,
                    zoom: 18
                }
            } 
            this.mapService.map = this.vehicleMap;

            //console.log("Wrapper", this.mapApiWrapper);
            //this.mapApiWrapper.getNativeMap()
            //    .then((map) => {
            //        console.log("Google", google);
            //        console.log("Map", map);
            //        var trafficLayer = new google.maps.TrafficLayer();
            //        trafficLayer.setMap(map);

            //    });
        });
 

        this.mapService.mode.subscribe(data => {           
            this.mapMode = data;       
            switch (data) {
                case 'vehicle':
                    if (this.mapFullscreen) {
                        this.mapClass = 'col-12 mapCard';
                    }
                    else {
                        this.mapClass = 'col-12 col-sm-7 col-md-8 col-lg-9';
                    }                    
                    break;
                case 'journey':
                    this.mapClass = 'col-12 col-lg-8 col-xl-9';
                    break;
                default:

                    break;
            }
        });

        this.mapService.mapInit.subscribe(data => {
            this.mapInit = data;
        });

        this.mapService.journey.subscribe(data => {
            if (data != this.journey) {
                this.journey = data;
            }
        });

        this.mapService.selectedVehicles.subscribe(data => {
            
            setTimeout(() => {
                
                    for (var i = 0; i < data.length; i++) {
                        let thisVehicle = data[i];

                        this.mapService.getIcon(thisVehicle.icon, (thisVehicle.colour), thisVehicle.displayName, thisVehicle.lastLocation, this.markerMode, function (data) {
                            thisVehicle.iconMarker = data;
                        });                        
                        
                    }
                    this.vehicles = data;
                                
            });
        });

        this.mapService.requestedPos.subscribe(data => { 

            this.position = {
                lat: 51.820413,
                lng: -0.839938,
                zoom: 18
            };;

               setTimeout(() => {
                    this.position = data;            
                });            
        });

    }

  //Modal for geofence button
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-title' }).result.then(result => {
      this.closeResult = `Close with: ${result}`;
    });
  }

  onSubmit() {
      this.vehicleService.sendFormDetails(this.congestionZones)
          .subscribe(result => {
            this.submitted = true;
        });
  }

    mouseMove(event: MouseEvent): void {
        if (this.mapFullscreen) {
            this.showControls();
            clearTimeout(this.hideControlsTimeout);
            this.hideControlsTimeout = setTimeout(() => { this.hideControls(); }, 2000);
        }
    }

    mapchanged(event: any): void {        
        this.mapService.mapMovedByUser.next(event);
    }

    refreshMap() {
        localStorage.setItem('showIdentifiers', (!this.showIdentifiers).toString());
        localStorage.setItem('markerMode', this.markerMode);
        this.vehicleService.selectVehicles(this.vehicles);
    }

    setFullscreen(event) {
        event.preventDefault();
        if (this.mapFullscreen) {
            this.mapFullscreen = false;
            this.mapService.mapFullscreen.next(false);
            this.vehicleMap.triggerResize()
        } else {
            this.hideControlsTimeout = setTimeout(() => { this.hideControls(); }, 2000);
            this.mapFullscreen = true;
            this.mapService.mapFullscreen.next(true);
            this.vehicleMap.triggerResize()
        }

    }

    showControls() {
        this.controlsHidden = false;
        this.vehicleMap.mapTypeControl = true;
        this.vehicleMap.scaleControl = true;
    }

    hideControls() {
        this.controlsHidden = true;
        this.vehicleMap.mapTypeControl = false;
        this.vehicleMap.scaleControl = false;
    }

    ngOnDestroy() {
        // Subscription clean-up
        this.routerSub$.unsubscribe();
    }
    private _changeTitleOnNavigation() {

        this.routerSub$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map(route => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            filter(route => route.outlet === 'primary'),
            mergeMap(route => route.data),)
            .subscribe((event) => {
                this._setMetaAndLinks(event);
            });
    }

    private _setMetaAndLinks(event) {

        // Set Title if available, otherwise leave the default Title
        const title = event['title']
            ? `${event['title']} - ${this.endPageTitle}`
            : `${this.defaultPageTitle} - ${this.endPageTitle}`;

        this.title.setTitle(title);

        const metaData = event['meta'] || [];
        const linksData = event['links'] || [];

        for (let i = 0; i < metaData.length; i++) {
            this.meta.updateTag(metaData[i]);
        }

        for (let i = 0; i < linksData.length; i++) {
            this.linkService.addTag(linksData[i]);
        }
    }
  // scroll to google map for journeys, this function gets invoked in child component journeyList
  scrollToMap(): void {
    this.vehicleMapContainer.nativeElement.scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'nearest' });
  }
}

