
import {first} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from '../../shared/services/auth.service';

interface Credentials {
    username: string,
    password: string
}

@Component({   
    templateUrl: './resetpassword.component.html',
    host: { 'class': 'container' }
})
export class ResetPasswordComponent implements OnInit {        

    password: string = '';
    confirmPassword: string = '';
    token: string = '';
    userId: string = '';
    resetSuccess: boolean = false;
    loading = false;
    submitted = false;
    returnUrl: string;    
    errorMessage: string = '';
    showSent: boolean = false;
    @ViewChild('resetForm') form;
    tokenChecked: boolean = false;
    validToken: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private auth: AuthService) { }

    ngOnInit() {
        // reset login status
        this.auth.logout();

        // Check the validity of the token
        this.token = this.route.snapshot.queryParams['token'];
        this.userId = this.route.snapshot.queryParams['userid']
        this.auth.checkResetToken(this.token, this.userId).pipe(first()).subscribe(data => {            
          if (data.success) {
                this.tokenChecked = true;
                this.validToken = true;
            } else {
                this.tokenChecked = true;
                this.validToken = false;
            }
        }, err => {
            this.tokenChecked = true;
            this.validToken = false;
        });
        
    }

    submitPassword() {      
        this.errorMessage = '';
        if (!this.form.valid) {
            this.submitted = true;
            return;
        }        
        this.loading = true;
        this.auth.resetPassword(this.token, this.userId, this.password, this.confirmPassword)
            .subscribe(
            data => {                
                this.resetSuccess = true;
                this.loading = false;
            },
            error => {   
                this.errorMessage = error;
                this.loading = false;
            });
    }
}
