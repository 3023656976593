
import {throwError as observableThrowError,  Observable, Subscription, BehaviorSubject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, Inject} from '@angular/core';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { AuthHttpService } from './auth-http.service';
import { ORIGIN_URL } from '../constants/baseurl.constants';
import { Vehicle } from '../../shared/models/Vehicle';
import { VehicleEvent } from '../../shared/models/VehicleEvent';
import * as moment from 'moment';





@Injectable()
export class EventService {


    constructor(private http: AuthHttpService, @Inject(ORIGIN_URL) private baseUrl: string) {
    }
    

    getEventsForVehicle(vehicle: Vehicle, startDate: Date, endDate: Date) {

      //let params: URLSearchParams = new URLSearchParams();
      let params: HttpParams = new HttpParams();

      params = params.append('vehicleId', vehicle.id.toString());
      params = params.append('start', startDate.toUTCString());
      params = params.append('end', endDate.toUTCString());        
        // Go get the journeys        
      return this.http.get(`/api/events/`, params).pipe(map((response: VehicleEvent[]) => response),catchError(this.handleError),);
    }

    reviveDateTime(key: any, value: any): any {
        if (typeof value === 'string') {
            if (moment(value).isValid()) {
                return moment(value);
            };            
        }
        return value;
    }

    private handleError(error: HttpResponse<any> | any) {
        // In a real world app, you might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            const body = error || '';
            const err = body['error'] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }
}
