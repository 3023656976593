import { Component, OnInit, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { User } from '../../shared/models/User';
import { UserService } from '../../shared/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../shared/services/company.service';
import { Company } from '../../shared/models/Company';
import { AuthService } from '../../shared/services/auth.service';
import { Observable } from 'rxjs'


@Component({
    selector: 'userForm',
    templateUrl: './userForm.component.html',
    host: { 'class': 'container' }
})
export class UserFormComponent implements OnInit {

    constructor(private userService: UserService, private activatedRoute: ActivatedRoute, private _location: Location, private companyService: CompanyService, private auth: AuthService) { }

    companies: Company[];
    model: User = undefined; 
    originalUser: User;
    success = false;
    loading = false;
    deleted = false;
    modelLoading = false;
    errorMessage: string = '';
    currentUser: User; 
    isAccountPage: boolean = false;

    onSubmit() {
        this.loading = true;
        this.errorMessage = "";

        if (this.currentUser.accessLevel > 0) {
            this.model.companyId = this.currentUser.companyId;
        }

        if (this.model.id == 'new') {

            this.userService.addUser(this.model).subscribe(result => {
                this.loading = false;
                this.model = <User>{};     
                this.success = true;
            }, error => this.errorMessage = <any>error);
        }
        else {
            this.userService.updateUser(this.originalUser, this.model).subscribe(result => {
                this.loading = false;                
                this.success = true;
            }, error => this.errorMessage = <any>error);
        }
    }

    resetForm() {
        this.success = false;
        this.model = <User>{};     
    }

  deleteUser() {
    this.userService.deleteUser(this.model).subscribe(result => {
      if (confirm("Are you sure to delete?")) {
        this.loading = false;
        this.deleted = true;
      }
    }, error => this.errorMessage = <any>error);
  }

    ngOnInit() { 
        this.model = <User>{};     

        this.modelLoading = true;
        var userId = this.activatedRoute.snapshot.params['id']; 
        
        this.isAccountPage = this.activatedRoute.snapshot.url[0].path == "account";

        this.auth.currentUser.subscribe(data => {
            this.currentUser = data;
            if (userId == undefined) {
                this.model.companyId = this.currentUser.companyId;
            }
        });

        if (this.isAccountPage) {
            userId = this.currentUser.id;
        }

        if (this.currentUser.accessLevel < 1) {
            this.companyService.companies.subscribe(data => {
                this.companies = data;
            });
        }

        this.userService.getUser(userId).subscribe(data => {                       
                this.modelLoading = false;
                this.model = Object.assign({}, data);
                this.originalUser = Object.assign({}, data);
                this.success = false;
            }                      
        );
    }

    goback() {
        this._location.back();
    }
    

}
