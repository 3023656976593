import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'menuTab',
  templateUrl: './menuTab.component.html'
})

export class MenuTabComponent implements OnInit {        
        
    constructor(private activatedRoute: ActivatedRoute) {                          
    }

    ngOnInit() {        
        this.activatedRoute.url.subscribe((thisRoute) => {
           this.active = '/' + thisRoute.join('/') == this.route;
        });
    }

    @Input('tabTitle') tabTitle : string;
    @Input('route') route : string;
    @Input('active') active: boolean = false;    
    @Input() templateRef: TemplateRef<any>;
}
