import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../shared/models/User';
import { VehicleService } from '../../shared/services/vehicle.service';
import { Vehicle } from '../../shared/models/Vehicle';
import { Router } from '@angular/router';


@Component({
  selector: 'app-vehicleData',
  templateUrl: './vehicleData.component.html',
  styleUrls: ['./vehicleData.component.scss'],
  host: { 'class': 'vehicleData-container' }
})
export class VehicleDataComponent implements OnInit {

  constructor(private auth: AuthService, private vehicleService: VehicleService, private router: Router) { }

    vehicles: Vehicle[];
    title: string = 'Citytalk Tracking Vehicle Data';    
  currentUser: User;
  loading: boolean = false;

    ngOnInit() {
      this.auth.currentUser.subscribe(data => {
        this.currentUser = data;
              
        this.vehicleService.vehicles.subscribe(data => {
          this.vehicles = data;                  
          this.loading = false;
        });
        this.vehicleService.loadAll(this.currentUser.companyId);
      });
    }

  //onActivate(event) {

  //  if (this.currentUser.accessLevel < 2) {

  //    if (event.type == 'click') {
  //      console.log('Activated Event', event);

  //      this.vehicleService.selectVehicle(event.row);
  //      this.router.navigate(['/admin/vehicles/']);

  //    }
  //  }
     
  //}
  
}
