
import {combineLatest as observableCombineLatest,  Observable, Subscription } from 'rxjs';

import {first} from 'rxjs/operators';
import { Inject, OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { VehicleService } from '../../shared/services/vehicle.service';
import { Vehicle } from '../../shared/models/Vehicle';
import { VehicleLocationService } from '../../shared/services/vehiclelocation.service';
import { VehicleLocation } from '../../shared/models/VehicleLocation';
import { MapService } from '../../shared/services/map.service';
import { isEqual } from 'lodash';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, PRIMARY_OUTLET, CanActivate } from '@angular/router';
import { MapPosition } from '../../shared/models/MapPosition';


@Component({
    selector: 'vehicleMap',
    templateUrl: './vehiclemap.component.html',
    styleUrls: ['./vehiclemap.component.css'],
    host: { 'class': 'col col-sm-5 col-md-4 col-lg-3' }
})


export class VehicleMapComponent implements OnDestroy {
    constructor(private router: Router, private vehicleService: VehicleService, private vehicleLocationService: VehicleLocationService,  private mapService: MapService) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
                this.selectedVehicles = [];                
            }
        });
    }

    latlngBounds;
    boundsCentre;    
    selectedVehicles: Vehicle[] = [];
    lat: number = 51.820413;
    lng: number = -0.839938;
    zoom: number = 16;
    locationsCollected: boolean = false;
    locations: VehicleLocation[];
    subscriptions: Subscription[] = [];
    mapFullscreen: boolean = false;
    updateMapTimeout: any;
    vehicleRefreshInterval: any;
    mapMovedTimer: any;
    

    ngOnInit() {

        this.mapService.mode.next("vehicle");
        this.mapService.mapInit.next(true);
        

        this.vehicleService.allVehiclesLoaded.pipe(first()).subscribe(data => {
            // Once the vehicle list has it's vehicles, tell it to select them
            this.vehicleService.selectAllVehicles();
            // Start a timer to get the vehicle locations
            this.vehicleRefreshInterval = setInterval(() => {
                this.getLocationsForSelected();
            }, 30000);
        });

        //// If the user zooms the map, pause updating for 1 minute
        //this.mapService.mapMovedByUser.subscribe(data => {            
        //    clearInterval(this.vehicleRefreshInterval);
        //    clearTimeout(this.mapMovedTimer);
        //    this.mapMovedTimer = setTimeout(() => {
        //        this.vehicleRefreshInterval = setInterval(() => {
        //            this.getLocationsForSelected();
        //        }, 30000);
        //    }, 60000)
        //});

        this.mapService.mapFullscreen.subscribe(data => {
            this.mapFullscreen = data;
            this.getBoundsFromVehicles();
        });

        observableCombineLatest(this.vehicleLocationService.vehicleLocationCache, this.vehicleService.selectedVehicles).subscribe(data => {                     
            if (data[0].length < 1) {
                return;
            }
            // Locations
            this.locations = data[0];   

            // Selected vehicles
            this.selectedVehicles = data[1];                

                this.assignLocationsToVehicles();
        }, err => console.error(err));
    }

    getLocationsForSelected() {          
        this.vehicleLocationService.updateVehicleCurrentLocations(this.selectedVehicles);
        //this.selectedVehicles.forEach(vehicle => {
        //    this.vehicleLocationService.getVehicleCurrentLocation(vehicle).first().subscribe();
        //});       
        
    }

    assignLocationsToVehicles() {        
        this.locations.forEach(location => {
            if (location.latitude != undefined) {
                if (this.selectedVehicles.length > 0) {
                    var vehicle = this.selectedVehicles.find(x => x.deviceId == location.deviceId);
                    var vehicleIndex = this.selectedVehicles.findIndex(x => x == vehicle);
                    if (vehicle != undefined) {
                        vehicle.lastLocation = location;
                        this.selectedVehicles[vehicleIndex] = vehicle;
                    }
                }
                this.locationsCollected = true;
            }
        });        
        if (this.selectedVehicles.length > 0) {
            this.getBoundsFromVehicles();
        }
        this.mapService.selectedVehicles.next(this.selectedVehicles);
    }

    getBoundsFromVehicles() {
        if (!this.locationsCollected) {
            // Something isn't ready, don't do anything
            return;
        }

        this.latlngBounds = new window['google'].maps.LatLngBounds();


        for (let i = 0; i < this.selectedVehicles.length; i++) {
            if (this.selectedVehicles[i].lastLocation != undefined && this.selectedVehicles[i].lastLocation.id > 0) {
                this.latlngBounds.extend(new window['google'].maps.LatLng(this.selectedVehicles[i].lastLocation.latitude, this.selectedVehicles[i].lastLocation.longitude))
            }
        }
        this.boundsCentre = this.latlngBounds.getCenter();
      

        // Send details to the map service but not too often
        clearTimeout(this.updateMapTimeout);
        this.updateMapTimeout = setTimeout(() => {
            var height = 600;
            var width = 800;
            if (this.mapService.map != undefined) {
                height = this.mapService.map._elem.nativeElement.parentNode.offsetHeight;
                width = this.mapService.map._elem.nativeElement.parentNode.offsetWidth;
            }

            let newPosition: MapPosition = {
                zoom: this.getBoundsZoomLevel(this.latlngBounds, { height: height, width: width }),
                lat: this.boundsCentre.lat(),
                lng: this.boundsCentre.lng()
            }                        
            this.mapService.requestedPos.next(newPosition);
        }, 1000);
    }

    getBoundsZoomLevel(bounds, mapDim) {
        var WORLD_DIM = { height: 256, width: 256 };
        var ZOOM_MAX = 21;

        function latRad(lat) {
            var sin = Math.sin(lat * Math.PI / 180);
            var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
        }

        function zoom(mapPx, worldPx, fraction) {
            return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
        }

        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();

        var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

        var lngDiff = ne.lng() - sw.lng();
        var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

        var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
        var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

        var returnZoom = Math.min(latZoom, lngZoom, ZOOM_MAX);
        if (returnZoom > 18) returnZoom = 18;
        if (returnZoom < 8) returnZoom = 8;
        return returnZoom - 1
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }
}
