﻿import { Pipe, PipeTransform } from '@angular/core';
import { Vehicle } from '../../shared/models/Vehicle';
import { VehicleLocationService } from '../../shared/services/vehiclelocation.service';


@Pipe({
    name: 'vehicleHasLocation',
    pure: false
})
export class VehicleHasLocationPipe implements PipeTransform {

    transform(items: Vehicle[]): Vehicle[] {      
        return items.filter(item => item.lastLocation != undefined && item.lastLocation.id > 0);        
    }
}