
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';

import {catchError, map, first} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { AuthHttpService } from './auth-http.service';
import { ORIGIN_URL } from '../constants/baseurl.constants';
import { ScheduledReport } from '../../shared/models/ScheduledReport';



import * as jsonpatch from 'fast-json-patch';

@Injectable()
export class ScheduledReportsService {

    public selectedReport: BehaviorSubject<ScheduledReport> = new BehaviorSubject(null);
    private _scheduledReports: BehaviorSubject<ScheduledReport[]>;
    private dataStore: {
        scheduledReports: ScheduledReport[];
    }

    constructor(private http: AuthHttpService, @Inject(ORIGIN_URL) private baseUrl: string) {   
        this.dataStore = { scheduledReports: [] };
        this._scheduledReports = <BehaviorSubject<ScheduledReport[]>>new BehaviorSubject([]);
    }

    selectReport(report:ScheduledReport) {
        this.selectedReport.next(report);
    }

    get scheduledReports() {
        return this._scheduledReports.asObservable();
    }

    loadAll() {
        return this.http.get(`/api/scheduledreports/`).pipe(map((res: ScheduledReport[]) => res),first(),).subscribe(data => {
            this.dataStore.scheduledReports = data;
            this._scheduledReports.next(Object.assign({}, this.dataStore).scheduledReports);
        });
    }

    getScheduledReport(id: number): Observable<ScheduledReport> {        
        return this.http.get(`/api/scheduledreports/` + id).pipe(map((response: ScheduledReport) => response));
    }

    deleteScheduledReport(scheduledReport: ScheduledReport): Observable<any> {
        return this.http.delete(`/api/ScheduledReports/` + scheduledReport.id).pipe(map(() => { this.loadAll() }));
    }

  
    addScheduledReport(scheduledReport: ScheduledReport) {

        // Remove unneeded bits
        scheduledReport.sendTimeStruct = undefined;
        
        return this.http.post(`/api/scheduledReports/`, scheduledReport).pipe(map(response => { this.loadAll(); return <ScheduledReport>response }),catchError(this.handleError),);                   
    }

    updateScheduledReport(scheduledReport: ScheduledReport, newScheduledReport: ScheduledReport) {

        // Remove the bits that don't need to go up
        newScheduledReport.sendTimeStruct = undefined;

        var patchDocument = jsonpatch.default.compare(scheduledReport, newScheduledReport)
        return this.http.patch(`/api/scheduledReports/` + newScheduledReport.id, patchDocument).pipe(map(response => { this.loadAll(); return response; }),catchError(this.handleError),);       

    }
    private handleError(error: HttpResponse<any> | any) {
        // In a real world app, you might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            const body = error || '';
            const err = body['error'] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }

}
