import { Component } from '@angular/core';
import { Company} from '../../shared/models/Company';
import { CompanyService } from '../../shared/services/company.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'companyList',
    templateUrl: './companyList.component.html'    
})
export class CompanyListComponent {
    companies: Company[];    
    loading: boolean = false;

    constructor( private companyService: CompanyService,) { }

    ngOnInit() {
            this.loading = true;
            this.companyService.companies.subscribe(data => {
                this.companies = data;
                this.loading = false;
            });            
    }


}

