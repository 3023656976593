
import { first } from 'rxjs/operators';
import { Component } from '@angular/core';
import { User } from '../../shared/models/User';
import { UserService } from '../../shared/services/user.service';
import { AuthService } from '../../shared/services/auth.service';
import { CompanyService } from '../../shared/services/company.service';
import { Company } from '../../shared/models/Company';


@Component({
  selector: 'userList',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userList.component.scss']
})
export class UserListComponent {
  users: User[];
  allUsers: User[];
  loading: boolean = false;
  model: User = undefined;
  errorMessage: string = '';
  currentUser: User;
  companies: Company[];
  selectedCompanyId: number = 0;

  constructor(private userService: UserService, private auth: AuthService, private companyService: CompanyService) { }

  ngOnInit() {


    this.auth.currentUser.subscribe(data => {
      this.currentUser = data;
      this.selectedCompanyId = this.currentUser.companyId;
      if (this.currentUser.accessLevel < 1) {
        this.companyService.companies.pipe(first())
          .subscribe(data => {
            this.companies = data;
          });
      }
    });

    this.loading = true;
    this.userService.users.subscribe(data => {
      this.allUsers = data;
      if (this.selectedCompanyId != 0) {
        this.users = this.allUsers.filter(x => x.companyId == this.selectedCompanyId);
      }
      else {
        this.users = data;
      }
      this.loading = false;
    });
  }

  onChange(companyId: number): void {
    this.selectedCompanyId = companyId;
    if (this.selectedCompanyId != 0) {
      this.users = this.allUsers.filter(x => x.companyId == this.selectedCompanyId);
    }
    else {
      this.users = this.allUsers;
    }
  }
}



