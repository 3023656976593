
import {forkJoin as observableForkJoin,  Observable, Subscription } from 'rxjs';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { Vehicle } from '../../shared/models/Vehicle';
import { VehicleService } from '../../shared/services/vehicle.service';
import { JourneyService } from '../../shared/services/journey.service';
import { IgnitionReport } from '../../shared/models/IgnitionReport';
import { ReportParams } from '../../shared/models/ReportParams'

@Component({
    selector: 'ignitionReport',
    templateUrl: './ignitionReport.component.html',   
    styleUrls: ['./ignitionReport.component.scss']
})
export class IgnitionReportComponent implements OnInit {

    @Input('report') report: string = '';
    @Input('reportTitle') reportTitle: string = '';
    selectedVehicles: Vehicle[] = [];    
    resultVehicles: Vehicle[] = [];

    gotIgnitions: boolean = false;
    gotResults: boolean = false;
    noResults: boolean = true;
    errorMessage: string = '';
    model: ReportParams = {
        reportTitle: '',
        from: undefined,
        fromTime: {
            "hour": 0,
            "minute": 0,
            "second": 0
        },
        to: undefined,
        toTime: {
            "hour": 23,
            "minute": 59,
            "second": 59
        },
        hoursMode: 'includeAll',
        weekdayEveningsMode: 'include',
        weekendMode: 'include',
        bankHolidays: 'include',
        onlyOverspeed: false,
        overrideSpeed: 70,
        minIgnition: 5        
    }; 

    loading = false;

    constructor(
        private vehicleService: VehicleService,
        private journeyService: JourneyService
    ) { }

    ngOnInit() {

        this.vehicleService.selectedVehicles.subscribe(data => {
            this.selectedVehicles = data;
        });

    }

    getTotal(items, prop) {
        return items.reduce(function (a, b) {
            return a + b[prop];
        }, 0);
    };



    getTimeString(ms) {
        ms = ms / 1000;
        ms = ms / 60;
        if (ms < 60) {
            if (ms.toFixed(0) == 1) {
                return ms.toFixed(0) + ' minute';
            }

            return ms.toFixed(0) + ' minutes';
        }
        var hrs = ms / 60;
        var mins = ms % 60;
        if (mins < 10) {
            return hrs.toFixed(0) + ' hours ' + mins.toFixed(0) + ' minutes';
        }
        else {
            return hrs.toFixed(0) + ' hours ' + mins.toFixed(0) + ' minutes';
        }
    }

    generateReport() {

        this.errorMessage = '';

        if (this.selectedVehicles.length < 1) {
            this.errorMessage = "Please select at least one vehicle";
            return;
        }

        this.loading = true;

                
        switch (this.report) {
            case 'ignition':
                this.generateIgnitionReport();
                break;
        }
    }

    backToForm() {
        this.gotResults = false;
        this.gotIgnitions = false;
        this.noResults = true;
    }


  generateIgnitionReport() {

        this.resultVehicles = []; 
        var subscriptions: Observable<IgnitionReport[]>[] = [];

        this.selectedVehicles.forEach(vehicle => {
            subscriptions.push(this.journeyService.getIgnitionsForVehicle(vehicle, this.model));
        });

        observableForkJoin(subscriptions).subscribe(data => {
            data.forEach(ignitionReportCollection => {
              if (ignitionReportCollection.length > 0) {
                var vehicle = this.selectedVehicles.find(x => x.deviceId == ignitionReportCollection[0].deviceId);
                vehicle.ignitionReports = ignitionReportCollection;
                    this.resultVehicles.push(vehicle);
                    this.noResults = false;
                }
            });
            this.gotIgnitions = true;
            this.gotResults = true;
            this.loading = false;
        });
    }


}
