
import {first} from 'rxjs/operators';
import { Component, OnInit, Inject, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Vehicle } from '../../shared/models/Vehicle';
import { VehicleService } from '../../shared/services/vehicle.service';
import { VehicleLocationService } from '../../shared/services/vehiclelocation.service';
import { JourneyService } from '../../shared/services/journey.service';
import { Observable, Subscription } from 'rxjs';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../shared/models/User';
import { CompanyService } from '../../shared/services/company.service';
import { Company } from '../../shared/models/Company';
import { VehicleLocation } from '../../shared/models/VehicleLocation';
import { PaginationInstance } from 'ngx-pagination';

@Component({
    selector: 'vehicleList',
    templateUrl: './vehiclelist.component.html',
    styleUrls: ['./vehiclelist.component.scss'],
    host: { 'class': 'container' },
    encapsulation: ViewEncapsulation.None
})
export class VehicleListComponent implements OnInit {
    vehicles: Vehicle[];
    selectedVehicle: Vehicle;
    selectedVehicles: Vehicle[] = [];
    loading: boolean = false;
    currentUser: User = <User>{ accessLevel:3}; 
    companies: Company[];
  selectedCompanyId: number = 0;
  gettingLocations: boolean = false;

    public config: PaginationInstance;

    @Input('showLinkStatus') showLinkStatus: boolean = false;
    @Input('showVehicleStatus') showVehicleStatus: boolean = false;
    @Input('allowMultiSelect') allowMultiSelect: boolean = false;
    @Input('getLocations') getLocations: boolean = true;
    @Input('title') title: string = '';
    @Input('showAlternative') showAlternative: boolean = false;
    @Input('selectAllOnCompanyChange') selectAllOnCompanyChange: boolean = false;

    constructor( @Inject(PLATFORM_ID) private platformId: Object, private auth: AuthService, private vehicleService: VehicleService, private vehicleLocationService: VehicleLocationService, private companyService: CompanyService) { }

    ngOnInit() {       

      this.loading = true;
        this.config = {
            id: 'custom',
            itemsPerPage: 100,
            currentPage: 1
        };

        if (this.getLocations) {
          this.vehicleLocationService.gettingLocations.subscribe(data => {
            if (data != undefined) {
              this.gettingLocations = data;
            }
          });
        }

        var showInListOption = localStorage.getItem('showInList');        
        if (showInListOption) {
            this.config.itemsPerPage = parseInt(showInListOption);
        }
            
            this.auth.currentUser.pipe(first()).subscribe(data => {
                this.currentUser = data;
                this.selectedCompanyId = this.currentUser.companyId;
                if (this.currentUser.accessLevel < 1) {
                    this.companyService.companies.pipe(first())
                        .subscribe(data => {
                            this.companies = data;
                        });
                }
                this.vehicleService.vehicles.subscribe(data => {
                    this.vehicles = data;
                    this.vehicles.forEach(vehicle => {
                      vehicle.status = '<i class="icon-refresh loadingSpinner list"></i>';
                      if (this.getLocations) {
                        this.vehicleLocationService.getVehicleCurrentLocation(vehicle).pipe(first()).subscribe(data => {
                          vehicle.status = this.getStatus(data);
                        }, err => {
                          vehicle.status = '<i class="statusIcon icon-question" aria-hidden="true"></i>';
                        });
                      }
                    });
                    this.vehicleService.selectedVehicles.subscribe(selected => {
                        this.selectedVehicles = selected;
                    });                    
                    this.loading = false;
                });
                this.vehicleService.loadAll(this.selectedCompanyId);
            });

            if (this.getLocations) {
              this.vehicleLocationService.vehicleLocationCache.subscribe(data => {
                this.assignLocationsToVehicles(data);
              });
            }
    }

    assignLocationsToVehicles(locations) {
        locations.forEach(location => {
            if (location.latitude != undefined) {
                if (this.selectedVehicles.length > 0) {
                    var vehicle = this.selectedVehicles.find(x => x.deviceId == location.deviceId);
                    var vehicleIndex = this.selectedVehicles.findIndex(x => x == vehicle);
                    if (vehicle != undefined) {
                        vehicle.lastLocation = location;
                        vehicle.status = this.getStatus(location);
                        this.selectedVehicles[vehicleIndex] = vehicle;
                    }
                }                
            }
        });       
    }

    changeShowAmount() {        
        localStorage.setItem('showInList', this.config.itemsPerPage.toString());        
    }

    getVehicles() {
        this.loading = true;
        this.vehicleService.loadAll();
    }

    vehicleSelected(vehicle: Vehicle) {      
        return (this.selectedVehicles.findIndex(x => x.id == vehicle.id) >= 0);
    }

    selectAll(e) {
        e.preventDefault();
        this.vehicleService.selectAllVehicles();
    }

    selectNone(e) {
        e.preventDefault();
        this.vehicleService.selectVehicles([]);
    }

    onSelect(vehicle: Vehicle): void {
        if (!this.allowMultiSelect) {
            if (vehicle == this.selectedVehicle) {
                this.selectedVehicle = undefined;
                this.vehicleService.selectVehicle(undefined);
            }
            else {
                this.selectedVehicle = vehicle;
                this.vehicleService.selectVehicle(vehicle);
            }
        }
        else {
            var existingSelection = this.selectedVehicles.findIndex(x => x.id == vehicle.id);
            if (existingSelection >= 0) {
                this.selectedVehicles.splice(existingSelection, 1);
            }
            else {
                this.selectedVehicles.push(vehicle);
            }
            this.vehicleService.selectVehicles(this.selectedVehicles);
        }
    }


  onChange(): void {
        this.vehicleService.loadAll(this.selectedCompanyId, this.selectAllOnCompanyChange);
    }

    getStatus(location: VehicleLocation) {         
        if (location == undefined || location.id < 1) {
            return '<i class="statusIcon icon-question" aria-hidden="true"></i>';
        }        
        var anHourAgo = new Date();
        anHourAgo.setHours(new Date().getHours() - 2);        
        if (new Date(location.deviceTime) < anHourAgo) {
            return '<i class="statusIcon icon-question" aria-hidden="true"></i>';
        }
        else if (location.speed == 0 && location.attributes.ignition == true) {
          return '<i class="statusIcon icon-parked" aria-hidden="true"></i><span class="idling">Idling</span>';
        }
        else if (location.speed == 0 && location.attributes.ignition !== true) {
            return '<i class="statusIcon icon-parked" aria-hidden="true"></i>';
        }
        var status = '<i class="statusIcon icon-mph" aria-hidden="true"></i>';
        var speed = location.speed / 1.609344;
        status = status += '<span>' + speed.toFixed(0) + " mph</span>";
        return status;
    };


  getLocationsForSelected() {
    this.vehicleLocationService.updateVehicleCurrentLocations(this.selectedVehicles);
        //this.selectedVehicles.forEach(vehicle => {
        //    this.vehicleLocationService.getVehicleCurrentLocation(vehicle).first().subscribe();
        //});       
  }
}

