import { NgModule, Inject } from '@angular/core';
import { RouterModule, ROUTES, ExtraOptions } from '@angular/router';
import { CommonModule, APP_BASE_HREF, DatePipe } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { VehicleHasLocationPipe } from './shared/pipes/vehiclehaslocation.pipe';
import { DateNotEmptyPipe } from './shared/pipes/datenotempty.pipe';
import { CustomFormsModule } from 'ng2-validation'
import { MomentModule } from 'ngx-moment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';

// Animation support
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// i18n support
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Directives
import { DirectionsMapDirective } from './shared/directives/map-directions.directive';
import { EqualValidator } from './shared/directives/equal-validator.directive';

// Components
import { AppComponent } from './app.component';
import { MenuTabComponent } from './components/menuTab/menuTab.component';
import { TabMenuComponent } from './components/tabMenu/tabMenu.component';
import { NavMenuComponent } from './components/navMenu/navMenu.component';
import { UserListComponent } from './components/userList/userList.component';
import { VehicleListComponent } from './components/vehicleList/vehicleList.component';
import { JourneyListComponent } from './components/journeyList/journeyList.component';
import { VehicleFormComponent, NgbDateCustomParserFormatter } from './components/vehicleForm/vehicleForm.component';
import { UserFormComponent } from './components/userForm/userForm.component';
import { CompanyListComponent } from './components/companyList/companyList.component';
import { CompanyFormComponent } from './components/companyForm/companyForm.component';
import { JourneyReportComponent } from './components/journeyReport/journeyReport.component';
import { EventReportComponent } from './components/eventReport/eventReport.component';
import { PointReportComponent } from './components/pointReport/pointReport.component';
import { IgnitionReportComponent } from './components/ignitionReport/ignitionReport.component';
import { ScheduledReportListComponent } from './components/scheduledReportsList/scheduledReportList.component';

// Pages
import { AdminComponent } from './pages/admin/admin.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { VehicleMapComponent } from './pages/vehiclemap/vehiclemap.component';
import { JourneyMapComponent } from './pages/journeymap/journeymap.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgottenComponent } from './pages/forgotten/forgotten.component';
import { ResetPasswordComponent } from './pages/resetpassword/resetpassword.component';
import { DownloadComponent } from './pages/download/download.component';
import { VehicleDataComponent } from './pages/vehicleData/vehicleData.component';

// Services
import { LinkService } from './shared/services/link.service';
import { VehicleService } from './shared/services/vehicle.service';
import { VehicleLocationService } from './shared/services/vehiclelocation.service';
import { UserService } from './shared/services/user.service';
import { CompanyService } from './shared/services/company.service';
import { JourneyService } from './shared/services/journey.service';
import { MapService } from './shared/services/map.service';
import { EventService } from './shared/services/event.service';
import { ScheduledReportsService } from './shared/services/scheduledReports.service';


import { ORIGIN_URL } from './shared/constants/baseurl.constants';

// Auth
import { AuthService } from './shared/services/auth.service';
import { AuthGuard } from './shared/services/auth-guard.service';

// Third party
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ColorPickerModule } from 'ngx-color-picker';
import { isEqual } from 'lodash'
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthHttpService } from './shared/services/auth-http.service';


export function createTranslateLoader(http: HttpClient, baseHref) {
  // Temporary Azure hack
  if (baseHref === null && typeof window !== 'undefined') {
    baseHref = window.location.origin;
  }
  // i18n files are in `wwwroot/assets/`
  return new TranslateHttpLoader(http, `/assets/i18n/`, '.json');
}

const routerOptions: ExtraOptions = {
  //tell the router to use the hash instead of HTML5 pushstate
  useHash: false,
  //in order to get the anchor / fragment schrolling to work at all, we need to enable it on the router
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    VehicleMapComponent,
    VehicleListComponent,
    JourneyListComponent,
    AdminComponent,
    VehicleFormComponent,
    VehicleHasLocationPipe,
    DateNotEmptyPipe,
    ReportsComponent,
    MenuTabComponent,
    TabMenuComponent,
    LoginComponent,
    ForgottenComponent,
    ResetPasswordComponent,
    UserListComponent,
    UserFormComponent,
    CompanyListComponent,
    CompanyFormComponent,
    JourneyMapComponent,
    DirectionsMapDirective,
    JourneyReportComponent,
    EventReportComponent,
    PointReportComponent,
    IgnitionReportComponent,
    EqualValidator,
    ScheduledReportListComponent,
    DownloadComponent,
    VehicleDataComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule.forRoot(),
    HttpClientModule,
    FormsModule,
    CustomFormsModule,
    ColorPickerModule,
    MomentModule,
    NgxDatatableModule,
    NgxPaginationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, [ORIGIN_URL]]
      }
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthService]
      }
    }),
    // App Routing
    RouterModule.forRoot([
      { path: 'login', component: LoginComponent },
      { path: 'forgotten', component: ForgottenComponent },
      { path: 'resetpassword', component: ResetPasswordComponent },
      { path: '', redirectTo: 'map', pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'map', component: VehicleMapComponent, canActivate: [AuthGuard] },
      { path: 'vehicles', component: VehicleDataComponent, canActivate: [AuthGuard] },
      { path: 'journeys', component: JourneyMapComponent, canActivate: [AuthGuard] },
      { path: 'admin/vehicles', component: AdminComponent, canActivate: [AuthGuard] },
      { path: 'admin/users', component: AdminComponent, canActivate: [AuthGuard] },
      { path: 'admin/users/:id', component: UserFormComponent, canActivate: [AuthGuard] },
      { path: 'admin/companies', component: AdminComponent, canActivate: [AuthGuard] },
      { path: 'admin/companies/:id', component: CompanyFormComponent, canActivate: [AuthGuard] },
      { path: 'admin', redirectTo: 'admin/users', canActivate: [AuthGuard] },
      { path: 'reports', redirectTo: 'reports/journey', canActivate: [AuthGuard] },
      { path: 'reports/journey', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'reports/overspeed', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'reports/behaviour', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'reports/idle', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'reports/ignition', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'account', component: UserFormComponent, canActivate: [AuthGuard] },
      { path: 'download', component: DownloadComponent },
      { path: '**', redirectTo: 'map' },
    ], routerOptions),

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC756h8bEgf6FvJfMEN8KgWT_cp_w7Kl0c'
    })

  ],
  providers: [
    AuthGuard,
    AuthHttpService,
    AuthService,
    UserService,
    LinkService,
    VehicleService,
    VehicleLocationService,
    TranslateModule,
    CompanyService,
    JourneyService,
    EventService,
    GoogleMapsAPIWrapper,
    MapService,
    ScheduledReportsService,
    DatePipe,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class AppModule {
}

export function jwtOptionsFactory(authService) {
  return {
    tokenGetter: () => {
      return authService.getToken('token');
    },
    whitelistedDomains: ['localhost:5000', 'www.citytalk-tracking.co.uk'],
  }
}
