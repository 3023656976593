
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {switchMap, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/Rx';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpParams, } from '@angular/common/http';
import { ResponseContentType } from '@angular/http';


@Injectable()
export class AuthHttpService {

  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(private http: HttpClient, private authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute) { }

  get(endpoint: string, body: any = undefined, responseType: any = undefined): Observable<any> {

    if (!this.jwtHelper.isTokenExpired(this.authService.getToken('token'))) {
      
      if (responseType == ResponseContentType.Blob) {
        return this.getBlobInternal(endpoint, body);
      }
      return this.getInternal(endpoint, body);
    }
    else if (!this.jwtHelper.isTokenExpired(this.authService.getToken('refresh'))) {
      return this.authService.getNewJwt().pipe(take(1),switchMap(data => {
        if (data) {
          return this.getInternal(endpoint, body);
        }
        else {
          this.router.navigate(['/login'], { queryParams: { returnUrl: this.activatedRoute.url } });
          return observableThrowError(data);

        }
      }),);
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.activatedRoute.url } });
    }

  }

  post(endpoint: string, body: any = undefined): Observable<any> {

    if (!this.jwtHelper.isTokenExpired(this.authService.getToken('token'))) {
      return this.postInternal(endpoint, body);
    }
    else if (!this.jwtHelper.isTokenExpired(this.authService.getToken('refresh'))) {
      console.log("Token expired, get a new one");
      return this.authService.getNewJwt().pipe(take(1),switchMap(data => {
        if (data) {
          return this.postInternal(endpoint, body);
        }
        else {
          this.router.navigate(['/login'], { queryParams: { returnUrl: this.activatedRoute.url } });
          return observableThrowError(data);
        }
      }),);
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.activatedRoute.url } });
    }
  }

  delete(endpoint: string, body: any = undefined): Observable<any> {

    if (!this.jwtHelper.isTokenExpired(this.authService.getToken('token'))) {
      return this.deleteInternal(endpoint, body);
    }
    else if (!this.jwtHelper.isTokenExpired(this.authService.getToken('refresh'))) {
      console.log("Token expired, get a new one");
      return this.authService.getNewJwt().pipe(take(1),switchMap(data => {
        if (data) {
          return this.deleteInternal(endpoint, body);
        }
        else {
          this.router.navigate(['/login'], { queryParams: { returnUrl: this.activatedRoute.url } });
          return observableThrowError(data);
        }
      }),);
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.activatedRoute.url } });
    }
  }

  patch(endpoint: string, body: any = undefined): Observable<any> {

    if (!this.jwtHelper.isTokenExpired(this.authService.getToken('token'))) {
      return this.patchInternal(endpoint, body);
    }
    else if (!this.jwtHelper.isTokenExpired(this.authService.getToken('refresh'))) {
      console.log("Token expired, get a new one");
      return this.authService.getNewJwt().pipe(take(1),switchMap(data => {
        if (data) {
          return this.patchInternal(endpoint, body);
        }
        else {
          this.router.navigate(['/login'], { queryParams: { returnUrl: this.activatedRoute.url } });
          return observableThrowError(data);
        }
      }),);
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.activatedRoute.url } });
    }
  }

  private getInternal(endpoint: string, body: any = undefined) {    
    return this.http.get(endpoint, { params: body});
  }

  private getBlobInternal(endpoint: string, body: any = undefined) {
    return this.http.get(endpoint, { params: body, responseType: 'blob' as 'json' });
  }

  private postInternal(endpoint: string, body: any = undefined) {
    return this.http.post(endpoint, body);
  }

  private deleteInternal(endpoint: string, body: any = undefined) {
    return this.http.delete(endpoint, body);
  }

  private patchInternal(endpoint: string, body: any = undefined) {
    return this.http.patch(endpoint, body);
  }

}
