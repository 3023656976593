import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../shared/models/User';


@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    host: { 'class': 'admin-container' }
})
export class AdminComponent implements OnInit {

    constructor(private auth: AuthService) { }


    title: string = 'Citytalk Tracking Admin';    
    currentUser: User;

    ngOnInit() {
        this.auth.currentUser.subscribe(data => {
            this.currentUser = data;            
        });
    }
  
}
