
import {first} from 'rxjs/operators';
import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { Vehicle } from '../../shared/models/Vehicle';
import { VehicleService } from '../../shared/services/vehicle.service';
import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../shared/models/User';
import { CompanyService } from '../../shared/services/company.service';
import { Company } from '../../shared/models/Company';
import { Weekdays } from '../../shared/models/Weekdays';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateParserFormatter, NgbDateStruct, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DateNotEmptyPipe } from '../../shared/pipes/datenotempty.pipe';




@Component({
  selector: 'vehicleForm',
  templateUrl: './vehicleForm.component.html',
  styleUrls: ['./vehicleForm.component.scss'],
  host: { 'class': 'card' },
  providers: [DateNotEmptyPipe]
})
export class VehicleFormComponent implements OnInit {

  @ViewChild('motDate') motDatePicker;
  @ViewChild('leasedDueDate') leasedDatePicker;
  @ViewChild('financeEndDate') financeDatePicker;
  @ViewChild('insuranceDueDate') insuranceDatePicker;
  @ViewChild('vehicleBuyDate') buyDatePicker;


  constructor(private vehicleService: VehicleService, private auth: AuthService, private companyService: CompanyService)
  {
  }
        
    model: Vehicle = <Vehicle>{};
    selectedVehicle: Vehicle = undefined;
    success = false;
    successMessage: string;
    loading = false;
    errorMessage: string = '';
    currentUser: User; 
    companies: Company[];
    workingHoursStartStruct: NgbTimeStruct;
    workingHoursEndStruct: NgbTimeStruct;
    workingDays: any = {
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: false,
        Sunday: false
    }

    ngOnInit() {

        this.auth.currentUser.subscribe(data => {
            this.currentUser = data;
        });

        if (this.currentUser.accessLevel < 1) {
            this.companyService.companies.pipe(first())
                .subscribe(data => {
                    this.companies = data;
                });
        }

        this.model.companyId = 0;

        //this.vehicleService.selectVehicle(undefined);  
        this.vehicleService.selectedVehicle.subscribe(result => {
            if (result != undefined) {
                this.model = Object.assign({}, result);
                // Convert the incoming vehicle times to timestructs                
                if (this.model.workingHoursStart != undefined) {
                    var hour = parseInt(this.model.workingHoursStart.substring(0, 2));
                    var minute = parseInt(this.model.workingHoursStart.substring(3, 5));
                    this.workingHoursStartStruct = { hour: hour, minute: minute, second: 0 };
                }

                if (this.model.workingHoursEnd != undefined) {
                    var hour = parseInt(this.model.workingHoursEnd.substring(0, 2));
                    var minute = parseInt(this.model.workingHoursEnd.substring(3, 5));
                    this.workingHoursEndStruct = { hour: hour, minute: minute, second: 0 };
                }

                // Convert the incoming working days number to the correct enabled days
                this.workingDays.Monday = ((this.model.workingDays & Weekdays.Monday) == Weekdays.Monday); 
                this.workingDays.Tuesday = ((this.model.workingDays & Weekdays.Tuesday) == Weekdays.Tuesday); 
                this.workingDays.Wednesday = ((this.model.workingDays & Weekdays.Wednesday) == Weekdays.Wednesday); 
                this.workingDays.Thursday = ((this.model.workingDays & Weekdays.Thursday) == Weekdays.Thursday); 
                this.workingDays.Friday = ((this.model.workingDays & Weekdays.Friday) == Weekdays.Friday); 
                this.workingDays.Saturday = ((this.model.workingDays & Weekdays.Saturday) == Weekdays.Saturday); 
              this.workingDays.Sunday = ((this.model.workingDays & Weekdays.Sunday) == Weekdays.Sunday);
                  

                this.selectedVehicle = result;
                this.success = false;
              this.successMessage = "";

              // shows the dates from the server in the right format using moment
              this.motDatePicker.nativeElement.value = moment(this.model.motDate).format('DD/MM/YYYY');
              if (this.motDatePicker.nativeElement.value.indexOf('0001') > -1) {
                this.motDatePicker.nativeElement.value = "";
              }
              this.leasedDatePicker.nativeElement.value = moment(this.model.leasedDueDate).format('DD/MM/YYYY');
              if (this.leasedDatePicker.nativeElement.value.indexOf('0001') > -1) {
                this.leasedDatePicker.nativeElement.value = "";
              }
              this.financeDatePicker.nativeElement.value = moment(this.model.financeEndDate).format('DD/MM/YYYY');
              if (this.financeDatePicker.nativeElement.value.indexOf('0001') > -1) {
                this.financeDatePicker.nativeElement.value = "";
              }
              this.insuranceDatePicker.nativeElement.value = moment(this.model.insuranceDueDate).format('DD/MM/YYYY');
              if (this.insuranceDatePicker.nativeElement.value.indexOf('0001') > -1) {
                this.insuranceDatePicker.nativeElement.value = "";
              }
              this.buyDatePicker.nativeElement.value = moment(this.model.vehicleBuyDate).format('DD/MM/YYYY');
              if (this.buyDatePicker.nativeElement.value.indexOf('0001') > -1) {
                this.buyDatePicker.nativeElement.value = "";
              }
              
            } else {
              this.model = <Vehicle>{ colour: '#2544b7', allowedTopSpeed: 70, workingDays: Weekdays.Monday | Weekdays.Tuesday | Weekdays.Wednesday | Weekdays.Thursday | Weekdays.Friday };
                this.workingHoursEndStruct = { hour: 17, minute: 30, second: 0 };
                this.workingHoursStartStruct = { hour: 8, minute: 30, second: 0 };

            }
        });
    }

  onMOTDateSelected(e) {
    this.model.motDate = new Date(e.year.toString() + '-' + e.month.toString() + '-' + e.day.toString());
  }

  onLeasedDateSelected(e) {
    this.model.leasedDueDate = new Date(e.year.toString() + '-' + e.month.toString() + '-' + e.day.toString());
  }

  onFinanceDateSelected(e) {
    this.model.financeEndDate = new Date(e.year.toString() + '-' + e.month.toString() + '-' + e.day.toString());
  }

  onInsuranceDateSelected(e) {
    this.model.insuranceDueDate = new Date(e.year.toString() + '-' + e.month.toString() + '-' + e.day.toString());
  }

  onBuyDateSelected(e) {
    this.model.vehicleBuyDate = new Date(e.year.toString() + '-' + e.month.toString() + '-' + e.day.toString());
  }

    onSubmit() {
        this.loading = true;
        this.errorMessage = "";

        // Build working days option from selected options
        this.model.workingDays = 0;
        if (this.workingDays.Monday) this.model.workingDays += Weekdays.Monday;
        
        if (this.workingDays.Tuesday) this.model.workingDays += Weekdays.Tuesday;
        if (this.workingDays.Wednesday) this.model.workingDays += Weekdays.Wednesday;
        if (this.workingDays.Thursday) this.model.workingDays += Weekdays.Thursday;
        if (this.workingDays.Friday) this.model.workingDays += Weekdays.Friday;
        if (this.workingDays.Saturday) this.model.workingDays += Weekdays.Saturday;
        if (this.workingDays.Sunday) this.model.workingDays += Weekdays.Sunday;

        this.model.workingHoursStart = ("0" + this.workingHoursStartStruct.hour).slice(-2) + ":" + ("0" + this.workingHoursStartStruct.minute).slice(-2) ;
        this.model.workingHoursEnd = ("0" + this.workingHoursEndStruct.hour).slice(-2) + ":" + ("0" + this.workingHoursEndStruct.minute).slice(-2) ;

        if (this.model.id == undefined) {

            this.vehicleService.saveVehicle(this.model).subscribe(result => {
                this.loading = false;
                this.model = <Vehicle>{};
                this.success = true;
                this.successMessage = "Thank you, your new vehicle has been added"
            }, error => this.errorMessage = <any>error);
        }
        else {
            this.vehicleService.updateSelectedVehicle(this.model).subscribe(result => {
                this.loading = false;
              this.model = <Vehicle>{ colour: '#2544b7', allowedTopSpeed: 70 };
                this.successMessage = "Thank you, this vehicle has been updated"
                this.success = true;
            }, error => this.errorMessage = <any>error);
        }
    }


    resetForm(vehicleForm: any) {
        console.log("Reset form");
        this.success = false;
        vehicleForm.reset();
      this.model = <Vehicle>{ colour: '#2544b7', allowedTopSpeed: 70 };
    }

    deleteVehicle() {

        this.loading = true;
        if (confirm("Are you sure to delete " + this.selectedVehicle.displayName + "?")) {
            this.vehicleService.deleteVehicle(this.selectedVehicle).subscribe(result => {
                this.loading = false;
              this.model = <Vehicle>{ colour: '#2544b7', allowedTopSpeed: 70 };
                this.success = true;
            }, error => this.errorMessage = <any>error);
        }
    }
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split("/");
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { day: toInteger(dateParts[0]), month: null, year: null };
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return {
          day: toInteger(dateParts[0]),
          month: toInteger(dateParts[1]),
          year: null
        };
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return {
          day: toInteger(dateParts[0]),
          month: toInteger(dateParts[1]),
          year: toInteger(dateParts[2])
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date
      ? `${isNumber(date.day) ? padNumber(date.day) : ""}/${isNumber(date.month) ? padNumber(date.month) : ""}/${
      date.year
      }`
      : "";
  }
}
export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return "";
  }
}

