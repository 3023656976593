
import {forkJoin as observableForkJoin,  Observable, Subscription } from 'rxjs';

import {first} from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Vehicle } from '../../shared/models/Vehicle';
import { VehicleService } from '../../shared/services/vehicle.service';
import { JourneyService } from '../../shared/services/journey.service';
import { Journey } from '../../shared/models/Journey';
import { ReportParams } from '../../shared/models/ReportParams'
import { Angular2Csv  } from 'angular2-csv';
import { ScheduledReport, ScheduledReportVehicle } from '../../shared/models/ScheduledReport';
import { ScheduledReportsService } from '../../shared/services/scheduledReports.service';
import { cloneDeep } from 'lodash';
import { JourneyReport } from '../../shared/models/JourneyReport';
import * as moment from 'moment';


@Component({
    selector: 'journeyReport',
    templateUrl: './journeyReport.component.html',   
    styleUrls: ['./journeyReport.component.scss'],
    animations: [
        trigger(
            'enterAnimation', [                

                transition(':enter', [        
                    style({ height: '0px', overflow: 'hidden' }),
                    animate('250ms', style({ height: '*' }))
                ]),
                transition(':leave', [                    
                    style({ height: '*', overflow: 'hidden' }),
                    animate('250ms', style({ opacity: '0' }))
                ])
            ]
        )
    ]
})
export class JourneyReportComponent implements OnInit {

    @ViewChild('smallReportTable') table: any;
    @Input('report') report: string = '';
    @Input('reportTitle') reportTitle: string = '';
    selectedVehicles: Vehicle[] = [];  
    resultReports: JourneyReport[] = [];
    resultVehicles: Vehicle[] = [];
    showScheduler: boolean = false;
    showCalendar: boolean = false;
    originalSelectedReport: ScheduledReport;

    scheduleLoading: boolean = false;
    loading: boolean = false;
    exportLoading: boolean = false;
    expanded: any = {};
    gotJourneys: boolean = false;
    gotResults: boolean = false;
    noResults: boolean = true;
    errorMessage: string = '';
    errorMessageSchedule: string = '';
    successMessage: string = '';
    model: ReportParams = {
        reportTitle: '',
        from: undefined,
        fromTime: {
            "hour": 0,
            "minute": 0,
            "second": 0
        },
        to: undefined,
        toTime: {
            "hour": 23,
            "minute": 59,
            "second": 59
        },   
        hoursMode: 'includeAll',
        weekdayEveningsMode: 'include',
        weekendMode: 'exclude',
        bankHolidays: 'exclude',  
        overrideSpeed: 70,
        overrideSpeedEnabled: false
    }; 

    readonly defaultSchedule: ScheduledReport = {
        scheduleType: 'monthly',
        sendEvery: 1,
        sendDay: 1,
        sendTimeStruct: {
            hour: 22,
            minute: 0,
            second: 0
        },
        sendTime: "22:00",
        bankHolidaysMode: this.model.bankHolidays,
        email: '',
        hoursMode: this.model.hoursMode,
        id: 0,
        reportType: this.report,
        vehicles: [],
        weekdayEveningsMode: this.model.weekdayEveningsMode,
        weekendMode: this.model.weekendMode,
        title: ''
    };
    schedule: ScheduledReport;
    originalSchedule: ScheduledReport;


    constructor(
        private vehicleService: VehicleService,
        private journeyService: JourneyService,
        private scheduledReportService: ScheduledReportsService
    ) { }

    ngOnInit() {

        this.vehicleService.selectedVehicles.subscribe(data => {
            this.selectedVehicles = data;
        });

        this.scheduledReportService.selectedReport.subscribe(data => {
            

            if (data != undefined) {
                this.successMessage = "";
                // Use object assign to stop the original report being updated
                this.schedule = cloneDeep(data);
                                
                // and save a reference to the original for the patch method
                this.originalSelectedReport = data;

                this.model.hoursMode = this.schedule.hoursMode;
                this.model.weekdayEveningsMode = this.schedule.weekdayEveningsMode;
                this.model.weekendMode = this.schedule.weekendMode;
                this.model.bankHolidays = this.schedule.bankHolidaysMode;
                this.showScheduler = true;
                this.showCalendar = false;
                this.schedule.sendTimeStruct = {
                    hour: parseInt(this.schedule.sendTime.substring(0, this.schedule.sendTime.indexOf(":"))),
                    minute: parseInt(this.schedule.sendTime.substring(this.schedule.sendTime.indexOf(":") + 1, this.schedule.sendTime.indexOf(":") + 3)),
                    second: 0
                };

                var selectedVehicles: Vehicle[] = [];
                var allVehicles: Vehicle[] = this.vehicleService._vehicles.value;
                this.schedule.vehicles.forEach(vehicle => {
                    // Find vehicle with this id                                        
                    var matchingVehicle = allVehicles.find(x => x.id == vehicle.vehicleId)
                    
                    if (matchingVehicle != undefined) {
                        selectedVehicles.push(matchingVehicle);
                    }
                });
                this.vehicleService.selectVehicles(selectedVehicles);

            } else {
                this.schedule = this.defaultSchedule;
            }
        });

    }

    getTotal(items, prop) {
        return items.reduce(function (a, b) {
            return a + b[prop];
        }, 0);
    };

    getTotalIdle(journeys: Journey[]) {
        var total = 0;
        journeys.forEach(journey => {
            total += journey.idleReports.reduce(function (a, b) {
                return a + b["idleTimeSeconds"];
            }, 0);
        });
        return total / 60;
    };


    getTimeString(ms) {
        ms = ms / 1000;
        ms = ms / 60;
        if (ms < 60) {
            return ms.toFixed(0) + ' mins';
        }
        var hrs = ms / 60;
        var mins = ms % 60;
        if (mins < 10) {
            return hrs.toFixed(0) + ':0' + mins.toFixed(0);
        }
        else {
            return hrs.toFixed(0) + ':' + mins.toFixed(0);
        }
    }

    generateReport() {

        this.showScheduler = false;

        this.errorMessage = '';

        if (this.selectedVehicles.length < 1) {
            this.errorMessage = "Please select at least one vehicle";
            return;
        }

        this.loading = true;

        //var weekendStart = this.model.weekendStart.hour + ':' + this.model.weekendStart.minute;
        //var weekendEnd = this.model.weekendEnd.hour + ':' + this.model.weekendEnd.minute;


        switch (this.report) {
            case 'journey':
                this.generateJourneyReport();
                break;
            case 'overspeed':
                this.generateOverspeedReport();
                break;

        }
    }

    backToForm() {
        this.gotResults = false;
        this.gotJourneys = false;
        this.noResults = true;
    }

    generateCSV() {
        var options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: true
        };

        var start = new Date(this.model.from.year, this.model.from.month - 1, this.model.from.day, this.model.fromTime.hour, this.model.fromTime.minute, this.model.fromTime.second);
        var end = new Date(this.model.to.year, this.model.to.month - 1, this.model.to.day, this.model.toTime.hour, this.model.toTime.minute, this.model.toTime.second);

        var filename = this.reportTitle + "-" + start + "-" + end;
        new Angular2Csv(this.resultVehicles, filename, options);
    }

    getExport() {

        this.exportLoading = true;

        switch (this.report) {
            case 'journey':
                this.exportJourneyReport();
                break;
            case 'overspeed':
                this.exportOverspeedReport();
                break;

        }
    }

    generateJourneyReport() {

        this.resultReports = []; 
        var subscriptions: Observable<JourneyReport>[] = [];

        this.selectedVehicles.forEach(vehicle => {
            subscriptions.push(this.journeyService.getJourneysForVehicle(vehicle, this.model));
        });

        observableForkJoin(subscriptions).subscribe(data => {
            data.forEach(journeyReport => {
                if (journeyReport.journeys.length > 0) {
                    journeyReport.vehicle = this.selectedVehicles.find(x => x.deviceId == journeyReport.vehicle.deviceId);
                    journeyReport.vehicle.journeys = journeyReport.journeys;
                    this.resultReports.push(journeyReport);
                    this.noResults = false;
                    //this.vehicleService.addJourneysToDevice(journeyCollection[0].deviceId, journeyCollection);
                }
            });
            this.gotJourneys = true;
            this.gotResults = true;
            this.loading = false;
        });
    }

    exportJourneyReport() {

        this.journeyService.exportJourneysForVehicle(this.selectedVehicles, this.model).subscribe(data => {
            // Do something with returning data
            var blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            var url = window.URL.createObjectURL(blob);
            var anchor = document.createElement('a');            
            //anchor.style = "display: none";
            anchor.href = url;
            anchor.download = "journeyExport " + moment().format('DD-mm-YYYY HH-mm') + ".xlsx";
            window.document.body.appendChild(anchor);
            anchor.click();
            window.document.body.removeChild(anchor);
            URL.revokeObjectURL(url);                        
            
            this.exportLoading = false;
        });
    }
    
    exportOverspeedReport() {

        this.model.onlyOverspeed = true;        

      this.journeyService.exportJourneysForVehicle(this.selectedVehicles, this.model).subscribe(data => {        
            var blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            var url = window.URL.createObjectURL(blob);
            var anchor = document.createElement('a');
            //anchor.style = "display: none";
            anchor.href = url;
            anchor.download = "overspeedExport " + moment().format('DD-mm-YYYY HH-mm') + ".xlsx";
            window.document.body.appendChild(anchor);
            anchor.click();
            window.document.body.removeChild(anchor);
            URL.revokeObjectURL(url);

            this.exportLoading = false;
        });
    }



    generateOverspeedReport() {
        this.resultReports = []; 
        var subscriptions: Observable<JourneyReport>[] = [];
        
                
        this.model.onlyOverspeed = true;        
        
        this.selectedVehicles.forEach(vehicle => {
            subscriptions.push(this.journeyService.getJourneysForVehicle(vehicle, this.model));
        });

        observableForkJoin(subscriptions).subscribe(data => {
            this.noResults = true;
            this.resultReports = [];
            data.forEach(journeyReport => {
                if (journeyReport.journeys.length > 0) {
                    journeyReport.vehicle = this.selectedVehicles.find(x => x.deviceId == journeyReport.vehicle.deviceId);
                    journeyReport.vehicle.journeys = journeyReport.journeys;
                    this.resultReports.push(journeyReport);
                    this.noResults = false;
                    //this.vehicleService.addJourneysToDevice(journeyCollection[0].deviceId, journeyCollection);
                }
            });
            this.gotJourneys = true;
            this.gotResults = true;
            this.loading = false;
        });
    }


    saveSchedule() {

        this.errorMessageSchedule = '';

        if (this.selectedVehicles.length < 1) {
            this.errorMessageSchedule = "Please select at least one vehicle";
            return;
        }

        this.schedule.reportType = this.report;
        // Add new selected vehicles to the schedule
        this.selectedVehicles.forEach(vehicle => {
            var alreadySavedVehicle = this.schedule.vehicles.find(x => x.vehicleId == vehicle.id);
            if (alreadySavedVehicle == undefined) {
                this.schedule.vehicles.push({ id: undefined, reportId: undefined, vehicleId: vehicle.id });
            }
        });

        // Remove any that are no longer selected
        var vehicleIDsToRemove: number[] = [];
        this.schedule.vehicles.forEach(vehicle => {
            var selectedVehicle = this.selectedVehicles.find(x => x.id == vehicle.vehicleId);
            if (selectedVehicle == undefined) {
                vehicleIDsToRemove.push(vehicle.vehicleId);
            }
        });


        vehicleIDsToRemove.forEach(idToRemove => {
            this.schedule.vehicles = this.schedule.vehicles.filter(x => x.vehicleId == idToRemove);
        });
           
        

        this.schedule.hoursMode = this.model.hoursMode;
        this.schedule.weekdayEveningsMode = this.model.weekdayEveningsMode;
        this.schedule.weekendMode = this.model.weekendMode;
        this.schedule.bankHolidaysMode = this.model.bankHolidays;

        this.scheduleLoading = true;

        this.schedule.sendTime = ("0" + this.schedule.sendTimeStruct.hour).slice(-2) + ":" + ("0" + this.schedule.sendTimeStruct.minute).slice(-2) + ':00';

        if (this.schedule.id == 0) {
            this.scheduledReportService.addScheduledReport(this.schedule).pipe(first()).subscribe(data => {
                this.scheduleLoading = false;
                this.successMessage = "Thank you, your scheduled report has been saved";
                this.showScheduler = false;
            });
        }
        else {
            this.scheduledReportService.updateScheduledReport(this.originalSelectedReport, this.schedule).pipe(first()).subscribe(data => {
                this.scheduleLoading = false;
                this.successMessage = "Thank you, your scheduled report has been saved";
                this.showScheduler = false;
            });
        }
    }

    deleteSelectedSchedule() {
        this.scheduleLoading = true;
        this.scheduledReportService.deleteScheduledReport(this.schedule).subscribe(data => {
            this.scheduleLoading = false;
            this.schedule = this.defaultSchedule;

        });
    }
  
    toggleExpandRow(row) {      
      this.table.rowDetail.toggleExpandRow(row);
    }

   onDetailToggle(event) {
    
    }

}
