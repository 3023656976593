
import { first } from 'rxjs/operators';
import { Component, OnInit, Inject, ViewChild, ElementRef, Host } from '@angular/core';
import { Vehicle } from '../../shared/models/Vehicle';
import { VehicleService } from '../../shared/services/vehicle.service';
import { JourneyService } from '../../shared/services/journey.service';
import { Subscription } from 'rxjs';
import { PLATFORM_ID } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../shared/models/User';
import { CompanyService } from '../../shared/services/company.service';
import { Company } from '../../shared/models/Company';
import { VehicleLocation } from '../../shared/models/VehicleLocation';
import { Journey } from '../../shared/models/Journey';
import * as moment from 'moment';
import { ReportParams } from '../../shared/models/ReportParams';
import { ExtraOptions } from '@angular/router';
import { AppComponent } from '../../app.component';


@Component({
    selector: 'journeyList',
  templateUrl: './journeylist.component.html',
  styleUrls: ['./journeyList.component.scss'],
    host: { 'class': 'mb-2' }
})
export class JourneyListComponent implements OnInit {
    selectedVehicle: Vehicle;
    selectedJourney: Journey;  
    currentUser: User = <User>{ accessLevel:3}; 
    companies: Company[];
    selectedCompanyId: number = 0;
    locations: VehicleLocation[];
    subscriptions: Subscription[] = [];
    journeyDay: Date = new Date();
    datePickerDay: undefined;
    loading: Boolean = true;
    calendarFormats = {
        lastDay: '[Yesterday] - Do MMM YYYY',
        sameDay: '[Today] - Do MMM YYYY',
        nextDay: '[Tomorrow] - Do MMM YYYY',
        lastWeek: '[Last] dddd - Do MMM YYYY',
        nextWeek: 'dddd - Do MMM YYYY',
        sameElse: 'L'
    }

  @ViewChild('datepicker') datePicker: any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private auth: AuthService, private vehicleService: VehicleService, private companyService: CompanyService, private journeyService: JourneyService, private parent: AppComponent) { }

    ngOnInit() {               
        this.vehicleService.selectedVehicle.subscribe(result => {
            if (result != this.selectedVehicle) {                
                this.selectedVehicle = result;
                this.selectedJourney = undefined;
                this.journeyService.selectJourney(undefined);

                if (result != undefined) {
                    this.getJourneys();
                }
            }
        });  
    }

    getJourneys() {      
        this.loading = true;
     
        var params: ReportParams = {
            reportTitle: '',
            from: {
                day: this.journeyDay.getDate(),
                month: this.journeyDay.getMonth() +1,
                year: this.journeyDay.getFullYear()
            },
            fromTime: {
                "hour": 0,
                "minute": 0,
                "second": 0
            },
            to: {
                day: this.journeyDay.getDate(),
                month: this.journeyDay.getMonth() +1 ,
                year: this.journeyDay.getFullYear()
            },
            toTime: {
                "hour": 23,
                "minute": 59,
                "second": 59
            },
            hoursMode: 'includeAll',
            weekdayEveningsMode: 'include',
            weekendMode: 'exclude',
            bankHolidays: 'exclude',
        }; 

        
        this.journeyService.getJourneysForVehicle(this.selectedVehicle, params).pipe(first()).subscribe(journeys => {
            // Send the journey back to the vehicle service
            this.vehicleService.addJourneysToVehicle(this.selectedVehicle.id, journeys.journeys);
            this.loading = false;
        });
    }

    
    selectJourney(journey: Journey, e: Event) {
        e.stopPropagation();
        if (journey == this.selectedJourney) {
            this.selectedJourney = undefined;
            this.journeyService.selectJourney(undefined);
        }
        else {
            this.selectedJourney = journey;
            this.journeyService.selectJourney(journey);
        }
    }

    nextDay() {
      this.journeyDay = moment(this.journeyDay).add(1, 'day').toDate();
        this.getJourneys();
    }

    prevDay() {
      this.journeyDay = moment(this.journeyDay).add(-1, 'day').toDate();
        this.getJourneys();
    }

    dateChange(event) {
      event.month = event.month - 1;
      this.journeyDay = moment(event).toDate();
      this.getJourneys();
    }

  scrollToMap() {
    //invokes parent scroll function from app component (parent is defined in constructor)
    this.parent.scrollToMap();
  }
}

