import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../shared/models/User';

@Component({
    selector: 'nav-menu',
    templateUrl: './navMenu.component.html',
    styleUrls: ['./navMenu.component.scss']
})
export class NavMenuComponent implements OnInit  {

  currentUser: User; 
  isIn = false;

    toggleState() {
      let bool = this.isIn;
      this.isIn = bool === false ? true : false; 
    }

    constructor(private auth: AuthService) { }

    ngOnInit() {
        this.auth.currentUser.subscribe(data => {
            this.currentUser = data;            
        });
    }

    logout() {
        this.auth.logout();
    }
}
