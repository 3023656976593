import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


@Pipe({
    name: 'dateNotEmpty',
    pure: false
})

export class DateNotEmptyPipe extends DatePipe implements PipeTransform {
  transform(value: any, format: string): any {

    if (value && value.indexOf('0001') > -1) {
      return "";
    } else {
      return new DatePipe('en-GB').transform(value, format);
    }
  }
}
