
import {throwError as observableThrowError,  Observable ,  BehaviorSubject, Subject } from 'rxjs';

import {map, first, catchError} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthHttpService } from './auth-http.service';
import { Vehicle } from '../../shared/models/Vehicle';
import { Journey } from '../../shared/models/Journey';
import * as jsonpatch from 'fast-json-patch';
import { Zones } from '../models/Zones';



@Injectable()
export class VehicleService {
    public _vehicles: BehaviorSubject<Vehicle[]>;
    private dataStore: {
        vehicles: Vehicle[];        
    }
    public selectedVehicle = new BehaviorSubject(null);
    public _selectedVehicles: BehaviorSubject<Vehicle[]>;
    public allVehiclesLoaded: Subject<boolean> = new Subject();

  constructor(private http: AuthHttpService, private httpClient: HttpClient) {
        this.dataStore = { vehicles: [] };
        this._vehicles = <BehaviorSubject<Vehicle[]>>new BehaviorSubject([]);        
        this._selectedVehicles = <BehaviorSubject<Vehicle[]>>new BehaviorSubject([]);        
    }

    get vehicles() {
        return this._vehicles.asObservable();        
    }

    get selectedVehicles() {
        return this._selectedVehicles.asObservable();
    }

    selectVehicle(vehicle: Vehicle) {
        this.selectedVehicle.next(vehicle);
    }

    selectVehicles(vehicles: Vehicle[]) {        
        this._selectedVehicles.next(vehicles);
    }

    selectAllVehicles() {
        var newVehicleList: Vehicle[] = [];
        this.dataStore.vehicles.forEach(vehicle => {
            newVehicleList.push(vehicle);
        });
        this.selectVehicles(newVehicleList);

    }
  
    loadAll(selectedCompanyId?: number, selectAll: boolean = false) {
      if (selectedCompanyId != undefined) {
        let params = new HttpParams().set('companyId', selectedCompanyId.toString());
        this.httpClient.get(`/api/vehicles`, { params }).pipe(map((res: Vehicle[]) => res)).subscribe(data => {
          this.dataStore.vehicles = data;

          for (var i = 0; i < this.dataStore.vehicles.length; i++) {
            this.dataStore.vehicles[i];
          }

          this._vehicles.next(Object.assign({}, this.dataStore).vehicles);
          if (selectAll) {
            this.selectAllVehicles();
          }
          this.allVehiclesLoaded.next(true);
        }, error => console.log('Could not load vehicles', error));
      }
    }

  sendFormDetails(zones: Zones){
    return this.http.post('api/Geofence', zones)
      .pipe(
        catchError(this.handleError));
  }
  
    getvehicle(vehicle: Vehicle): Observable<Vehicle> {
        return this.http.get(`/api/vehicles/` + vehicle.id).pipe(map((response: Vehicle) => response));
    }

  deleteVehicle(vehicle: Vehicle): Observable<any> {
        return this.http.delete(`/api/vehicles/` + vehicle.id).pipe(first(),map(data => {
            var vehicleIndexToDelete = this.dataStore.vehicles.findIndex(o => o.id == vehicle.id);
            this.dataStore.vehicles.splice(vehicleIndexToDelete, 1);
            this._vehicles.next(Object.assign({}, this.dataStore).vehicles);
        }),catchError(this.handleError),);        
    }

    //updatevehicle(vehicle: Vehicle): Observable<any> {
    //    return this.http.put(`/api/vehicles/` + vehicle.id, vehicle);
    //}

    //updatevehiclelastlocation(vehicle: Vehicle) {
    //    var vehicleToUpdate = this.dataStore.vehicles.findIndex(o => o.id == vehicle.id);
    //    this.dataStore.vehicles[vehicleToUpdate].lastLocation = vehicle.lastLocation;
    //    this.dataStore.vehicles[vehicleToUpdate].hasLocation = true;
    //    this._vehicles.next(this.dataStore.vehicles);
    //}
    
    updatevehicleiconimage(vehicle: Vehicle) {
        var vehicleToUpdate = this.dataStore.vehicles.findIndex(o => o.id == vehicle.id);
        this.dataStore.vehicles[vehicleToUpdate].iconMarker = vehicle.iconMarker;        
        this._vehicles.next(Object.assign({}, this.dataStore).vehicles);
    }

    addJourneysToVehicle(vehicleId: number, journeys: Journey[]) {
        var vehicleToUpdate = this.dataStore.vehicles.findIndex(o => o.id == vehicleId);
        this.dataStore.vehicles[vehicleToUpdate].journeys = journeys;
        this._vehicles.next(Object.assign({}, this.dataStore).vehicles);
    }

    addJourneysToDevice(deviceId: number, journeys: Journey[]) {
        var vehicleToUpdate = this.dataStore.vehicles.findIndex(o => o.deviceId == deviceId);
        this.dataStore.vehicles[vehicleToUpdate].journeys = journeys;
        this._vehicles.next(Object.assign({}, this.dataStore).vehicles);
    }

    saveVehicle(vehicle: Vehicle) {
        // Update icon
        //this.getIcon(this.getIconString(vehicle.icon), (vehicle.colour), function (data) {
        //    vehicle.iconImage = data;
        //});

      return this.http.post(`/api/vehicles`, vehicle).pipe(map((response: Vehicle) => {
            let vehicle = response;
            this.dataStore.vehicles.push(vehicle);
            this._vehicles.next(Object.assign({}, this.dataStore).vehicles);
            return vehicle;
      }), catchError(this.handleError));
    }

    updateSelectedVehicle(newVehicle: Vehicle) {

        var patchDocument = jsonpatch.default.compare(this.selectedVehicle.value, newVehicle)
        
        return this.http.patch(`/api/vehicles/` + newVehicle.id, patchDocument).pipe(map(response => {
            var vehicleToUpdate = this.dataStore.vehicles.findIndex(o => o.id == newVehicle.id);
            this.dataStore.vehicles[vehicleToUpdate] = newVehicle;
            this._vehicles.next(Object.assign({}, this.dataStore).vehicles);
            return response;
        }),catchError(this.handleError),);
    }

    private handleError(error: HttpResponse<any> | any) {
        // In a real world app, you might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            const body = error || '';
            const err = body['error'] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }

}
