﻿import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from '../models/User';
import { Company } from '../models/Company';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private auth: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        return this.auth.loggedIn().then(() => {
            var token = this.auth.jwtHelper.decodeToken(localStorage.getItem('token'));
            var user: User = <User>{
                firstName: token.firstName,
                lastName: token.lastName,
                accessLevel: token.level,
                userName: token.userName,
                id: token.sub,
                company: <Company>JSON.parse(token.company),
                companyId: token.companyId
            };
            this.auth.currentUser.next(user);
            return true;
        }, () => {
            this.auth.currentUser.next(undefined);
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        });
       
    }
}