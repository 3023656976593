
import {first} from 'rxjs/operators';
import { Inject, NgZone, ViewChild, ViewEncapsulation } from '@angular/core';
import { Component } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { VehicleService } from '../../shared/services/vehicle.service';
import { Vehicle } from '../../shared/models/Vehicle';
import { JourneyService } from '../../shared/services/journey.service';
import { Journey } from '../../shared/models/Journey';
import { BehaviorSubject } from 'rxjs';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmPolyline, AgmPolylinePoint } from '@agm/core';
import { VehicleLocationService } from '../../shared/services/vehiclelocation.service';
import { VehicleLocation } from '../../shared/models/VehicleLocation';
import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../shared/models/User';
import { Company } from '../../shared/models/Company';
import { DirectionsMapDirective } from '../..//shared/directives/map-directions.directive';
import { MapService } from '../../shared/services/map.service';

declare var google: any;

@Component({
    selector: 'journeyMap',
    templateUrl: './journeymap.component.html',
    styleUrls: ['./journeymap.component.css'],
  providers: [GoogleMapsAPIWrapper],
  encapsulation: ViewEncapsulation.None,
    host: { 'class': 'col-12 col-lg-4 col-xl-3' }

})


export class JourneyMapComponent {

    constructor(
        private vehicleService: VehicleService,
        private auth: AuthService,
        private journeyService: JourneyService,
        private zone: NgZone,
        private mapsAPILoader: MapsAPILoader,
        private gmapsApi: GoogleMapsAPIWrapper,
        private mapService: MapService) {    }

    @ViewChild(DirectionsMapDirective) vc: DirectionsMapDirective;

    latlngBounds;
    boundsCentre;
    vehicles: Vehicle[];
    lat: number = 51.820413;
    lng: number = -0.839938;
    zoom: number = 16;
    vehicleSelected: boolean = false;
    selectedJourney: Journey = <Journey>{ points: [] };
    company: Company;
    waypoints: any[];
    directionsDisplay: any;    
    journeyOrigin: any;
    journeyDestination: any;

    ngOnInit() {

        this.mapService.mode.next("journey");
        this.mapService.mapInit.next(true);


        this.auth.currentUser.pipe(first()).subscribe(data => {
            this.company = data.company;
        });         

        this.vehicleService.vehicles.subscribe(data => {
            this.vehicles = data;            
        });

                     

      this.journeyService.selectedJourney.subscribe(journey => {
          this.selectedJourney = journey;
          if (this.selectedJourney != null && this.selectedJourney != undefined) {   
              this.journeyOrigin = { longitude: this.selectedJourney.startLon, latitude: this.selectedJourney.startLat };
              this.journeyDestination = { longitude: this.selectedJourney.endLon, latitude: this.selectedJourney.endLat };
            this.journeyService.getPointsOnJourney(this.selectedJourney).subscribe(points => {
              points = points.filter(x => x.valid);
                  this.selectedJourney.points = points;
                  this.mapService.journey.next(this.selectedJourney);
                  this.getBoundsFromPoints();
                  //this.waypoints = [];
                  //points.forEach(point => {
                  //    this.waypoints.push({ location: new google.maps.LatLng(point.latitude, point.longitude) });
                  //});                   
                  //if (this.vc.directionsDisplay === undefined) {
                  //    this.vc.directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: true });                      
                  //}
                  //this.vc.waypoints = this.waypoints;
                  //this.vc.updateDirections();                  
              });
          }
      });

       
    }
  
    getBoundsFromPoints() {
       
        this.latlngBounds = new window['google'].maps.LatLngBounds();

        for (let i = 0; i < this.selectedJourney.points.length; i++) {            
            this.latlngBounds.extend(new window['google'].maps.LatLng(this.selectedJourney.points[i].latitude, this.selectedJourney.points[i].longitude))           
        }
        this.boundsCentre = this.latlngBounds.getCenter();

        this.mapService.requestedPos.next(
            {
                zoom: this.getBoundsZoomLevel(this.latlngBounds, { height: 500, width: 800 }),
                lat: this.boundsCentre.lat(),
                lng: this.boundsCentre.lng()
            });         
    }

    getBoundsZoomLevel(bounds, mapDim) {
        var WORLD_DIM = { height: 256, width: 256 };
        var ZOOM_MAX = 21;

        function latRad(lat) {
            var sin = Math.sin(lat * Math.PI / 180);
            var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
        }

        function zoom(mapPx, worldPx, fraction) {
            return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
        }

        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();

        var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

        var lngDiff = ne.lng() - sw.lng();
        var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

        var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
        var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

        var returnZoom = Math.min(latZoom, lngZoom, ZOOM_MAX);
        if (returnZoom > 18) returnZoom = 18;
        return returnZoom
    }

}
