﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from '../../shared/services/auth.service';

interface Credentials {
    username: string,
    password: string
}

@Component({   
    templateUrl: './forgotten.component.html',
    host: { 'class': 'container' }
})
export class ForgottenComponent implements OnInit {        

    username: string = '';
    loading = false;
    submitted = false;
    returnUrl: string;    
    errorMessage: string = '';
    showSent: boolean = false;
    @ViewChild('forgottenForm') form;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private auth: AuthService) { }

    ngOnInit() {
        // reset login status
        this.auth.logout();
    }

    submitUsername() {      
        this.errorMessage = '';
        if (!this.form.valid) {
            this.submitted = true;
            return;
        }        
        this.loading = true;
        this.auth.forgotten(this.username)
            .subscribe(
            data => {                
                this.showSent = true;
                this.loading = false;
            },
            error => {   
                this.errorMessage = error;
                this.loading = false;
            });
    }
}
