import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';


@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    host: { 'class': 'col-12' },
  styleUrls: ['./reports.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class ReportsComponent implements OnInit {

    //selectedVehicles: Vehicle[] = [];
    
    constructor(
        //private vehicleService: VehicleService,
        //private journeyService: JourneyService
    ) { }

    ngOnInit() {

        //this.vehicleService.selectedVehicles.subscribe(data => {
        //    this.selectedVehicles = data;
        //});

    }

    //generateJourneyReport() {

    //    var start = new Date();
    //    start.setHours(0, 0, 0, 0);
    //    var end = new Date();
    //    end.setHours(23, 59, 59, 999);
    //    var subscriptions: Observable<Journey[]>[] = [];
    //    this.selectedVehicles.forEach(vehicle => {
    //        subscriptions.push(this.journeyService.getJourneysForVehicle(vehicle, start, end));                                
    //    });
    //    Observable.forkJoin(subscriptions).subscribe(data => {
    //        data.forEach(journeyCollection => {
    //            if (journeyCollection.length > 0) {
    //                this.vehicleService.addJourneysToDevice(journeyCollection[0].deviceId, journeyCollection);
    //            }
    //        });
    //        this.gotJourneys = true;
    //    });
    //}

}
