
import {throwError as observableThrowError, of as observableOf,  Observable, Subscription, BehaviorSubject } from 'rxjs';
import {first, map} from 'rxjs/operators';
import { Injectable, Inject, Injector, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { AuthHttpService } from './auth-http.service';
import { ORIGIN_URL } from '../constants/baseurl.constants';
import { VehicleLocation } from '../../shared/models/VehicleLocation';
import { Vehicle } from '../../shared/models/Vehicle';


@Injectable()
export class VehicleLocationService {

    private _vehicleLocationCache: BehaviorSubject<VehicleLocation[]>;
    private dataStore: {
        vehicleLocationCache: VehicleLocation[];
    }
    vehicles: Vehicle[];   

    public gettingLocations: BehaviorSubject<boolean>;

    constructor(private http: AuthHttpService, @Inject(ORIGIN_URL) private baseUrl: string) {
        this.dataStore = { vehicleLocationCache: [] };
        this.gettingLocations = <BehaviorSubject<boolean>>new BehaviorSubject(false);
        this._vehicleLocationCache = <BehaviorSubject<VehicleLocation[]>>new BehaviorSubject([]);              
    }

    get vehicleLocationCache() {
        return this._vehicleLocationCache.asObservable();
    }

  getVehicleCurrentLocation(vehicle: Vehicle): Observable<VehicleLocation> {
        if (!vehicle.deviceId) {
            return observableOf(undefined);
        }
        let cachedLocation: VehicleLocation = undefined;

        if (this.dataStore.vehicleLocationCache.length > 1) {
            cachedLocation = this.dataStore.vehicleLocationCache.filter(o => o.deviceId == vehicle.deviceId)[0];
        }
        if (cachedLocation != undefined) {            
            if (cachedLocation.retrievalTime > new Date(new Date().getTime() - 30000)) {
                // Cached location is recent, simply return it
                return observableOf(cachedLocation);
            }
            else {
                return this.getLocationFromServer(cachedLocation);
            }
        }
        else {
            var thisLocation: VehicleLocation = <VehicleLocation>{
                id: 0,
                vehicleId: vehicle.id,
                deviceId: vehicle.deviceId,
                latitude: undefined,
                longitude: undefined,
                deviceTime: undefined,
                speed: undefined,
                retrievalTime: undefined,
                retrieving: false,
                status: '',
                attributes: { ignition: false },
                course: 0,
              address: '',
                valid: true
            };
            this.dataStore.vehicleLocationCache.push(thisLocation);     
            return this.getLocationFromServer(thisLocation);    
        }
        
    }

  updateVehicleCurrentLocations(vehicles: Vehicle[]) {
    this.gettingLocations.next(true);
    var deviceIdsToFetch: number[] = [];
        vehicles.forEach(vehicle => {
          if (!vehicle.deviceId) {
                return;
            }
            let cachedLocation: VehicleLocation = undefined;

            if (this.dataStore.vehicleLocationCache.length > 1) {
              cachedLocation = this.dataStore.vehicleLocationCache.filter(o => o.deviceId == vehicle.deviceId)[0];
            }
            if (cachedLocation != undefined) {
                if (cachedLocation.retrievalTime > new Date(new Date().getTime() - 28000)) {
                    // Cached location is recent, don't go get it
                  return;
                }
                else {
                  deviceIdsToFetch.push(vehicle.deviceId);
                    return;
                }
            }
            else {
              deviceIdsToFetch.push(vehicle.deviceId);
            }
        });
    

        // Go get the locations  
    if (deviceIdsToFetch.length > 0) {
      this.http.post(`/api/vehiclelocations/devices/`, deviceIdsToFetch).pipe(map((response: VehicleLocation[]) => {
              var locations = response;
                locations.forEach(location => {

                    // Find location in cache
                    location.retrievalTime = new Date();
                    location.retrieving = false;
                    let locationToUpdate = this.dataStore.vehicleLocationCache.findIndex(o => o.deviceId == location.deviceId);
                    if (locationToUpdate == undefined) {
                        this.dataStore.vehicleLocationCache.push(location);
                    }
                    else {
                        this.dataStore.vehicleLocationCache[locationToUpdate] = location;
                    }
                });
                this._vehicleLocationCache.next(Object.assign({}, this.dataStore).vehicleLocationCache);
                // Make the spinner go for a second to show that something is happening
                setTimeout(() => {
                    this.gettingLocations.next(false);
                }, 1000);
            }, err => {
                return;
            }),first(),).subscribe();
        } else {
            setTimeout(() => {
                this.gettingLocations.next(false);
            }, 1000);
            
        }
    }

  getLocationFromServer(cachedLocation: VehicleLocation): Observable<VehicleLocation> {
        if (cachedLocation.retrieving) {
            return observableOf(cachedLocation);
        }        
        let locationToUpdate = this.dataStore.vehicleLocationCache.findIndex(o => o == cachedLocation);
        // Mark this location as retrieving so that it doesn't get fetched again
        cachedLocation.retrieving = true;
        this.dataStore.vehicleLocationCache[locationToUpdate] = cachedLocation;

        // Go get the locations
        let newLocation: VehicleLocation;
      return this.http.get(`/api/vehiclelocations/device/` + cachedLocation.deviceId).pipe(map(response => {
        newLocation = response as VehicleLocation;
            if (newLocation == undefined) {
                cachedLocation.retrievalTime = new Date();
                cachedLocation.retrieving = false;
                let locationToUpdate = this.dataStore.vehicleLocationCache.findIndex(o => o == cachedLocation);
                this.dataStore.vehicleLocationCache[locationToUpdate] = cachedLocation;
                this._vehicleLocationCache.next(Object.assign({}, this.dataStore).vehicleLocationCache);                  
                return cachedLocation;
            }
            newLocation.retrievalTime = new Date();
            newLocation.retrieving = false;
            let locationToUpdate = this.dataStore.vehicleLocationCache.findIndex(o => o.deviceId == cachedLocation.deviceId);
            this.dataStore.vehicleLocationCache[locationToUpdate] = newLocation;
            this._vehicleLocationCache.next(Object.assign({}, this.dataStore).vehicleLocationCache);                        
            return newLocation;
        }, err => {      
                cachedLocation.id = -1;
                cachedLocation.retrieving = false;
                cachedLocation.retrievalTime = new Date();
                let locationToUpdate = this.dataStore.vehicleLocationCache.findIndex(o => o.deviceId == cachedLocation.deviceId);
                this.dataStore.vehicleLocationCache[locationToUpdate] = cachedLocation;
                this._vehicleLocationCache.next(Object.assign({}, this.dataStore).vehicleLocationCache);                
                return observableOf(cachedLocation);
        }));

    }

    //cacheAllVehicleCurrentLocations() {
    //    console.log("Getting all locations");
    //    var requests: Observable<VehicleLocation>[] = [];
    //    for (var i = 0; i < this.vehicles.length; i++) {
    //        let thisVehicle = this.vehicles[i];
    //        if (thisVehicle.deviceId != undefined && thisVehicle.deviceId != null) {                
    //            requests.push(this.getVehicleCurrentLocation(thisVehicle));
    //        }
    //    }

    //    Observable.forkJoin(requests).subscribe(data => {
    //        // Once all locations are fetched, notify subscribers that we fetched all data
    //        console.log("all locations fetched");
    //        this._vehicleLocationCache.next(Object.assign({}, this.dataStore).vehicleLocationCache);
    //    });
    //}


  
    private handleError(error: HttpResponse<any> | any) {
        // In a real world app, you might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            const body = error || '';
            const err = body['error'] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }        
        return observableThrowError(errMsg);
    }
}
