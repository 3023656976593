import { Component, QueryList, ContentChildren } from '@angular/core';
import { MenuTabComponent } from '../menuTab/menuTab.component';
import { RouterModule } from '@angular/router';


@Component({
  selector: 'tabMenu',
  templateUrl: './tabMenu.component.html',
  styleUrls: ['./tabMenu.component.scss'],
})
export class TabMenuComponent  {

  @ContentChildren(MenuTabComponent) tabs: QueryList<MenuTabComponent>;

    selectTab(tab: MenuTabComponent) {
        this.tabs.forEach((tab) => {
            tab.active = false;
        });
        tab.active = true
    }
}
